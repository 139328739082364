import React, { useContext, useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import OperationsMapBookingsUpdateModal from './OperationsMapBookingsUpdateModal';
import OperationsMapAddNewBookingModal from './OperationsMapBookingsAddNewModal';

import UserRoleContext from '../../Contexts/UserRoleContext';
import Constants from '../../Constants';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { Columns } from './OperationsMapBookingTableColumns';
import { FaSort, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { FaRegSquarePlus } from "react-icons/fa6";
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { useNavigate } from "react-router-dom";
import { checkAuthenticateIssue } from "../Login/LoginUtil";
import useRefreshToken from "../../Contexts/useRefreshToken";
import { handleDateChange } from "./OperationsMapDate";
import { VesselImage, rotateVessel, handleDragEnd, fetchVesselImages } from './OperationsMapVesselGroup';
import handleAddToReports from "./OperationsMapAddToReports";
import { FaFolderPlus } from "react-icons/fa";
import useOperationsMapBookingResources from './useOperationsMapBookingResources';
import { useSelectedDateContext } from "../../Contexts/useSelectedDateContext";
import { useVesselDataContext } from "../../Contexts/useVesselDataContext";
import { useGangwayReservationsContext } from "../../Contexts/GangwayReservationsContext";
import { useTideContext } from "../../Contexts/TideContext";
import { useRenderFlag } from '../../Contexts/RenderFlagContext';
import { useBookingResourcesContext } from "../../Contexts/useBookingResourcesContext";

function OperationsMapBookingTable({ setReportImages, setVesselImageOrientation }) {

    const { renderVersion, incrementRenderVersion } = useRenderFlag();


    const { vesselData, setVesselData } = useVesselDataContext();
   // const { gangwayReservations, setGangwayReservations } = useGangwayReservationsContext();

    const [selectedBooking, setSelectedBooking] = useState(null);
    const [showOperationsMapBookingsUpdateModal, setShowOperationsMapBookingsUpdateModal] = useState(false);
    const [showOperationsMapAddNewBookingModal, setShowOperationsMapAddNewBookingModal] = useState(false);
    const { userRole } = useContext(UserRoleContext);
    //const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    //const [selectedDate, setSelectedDate] = useState(new Date());
    const [bookings, setBookings] = useState([]);
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
    const navigate = useNavigate();

    const { selectedDate, setSelectedDate } = useSelectedDateContext();
    const { tide, setTide } = useTideContext();

    //useEffect(() => {
    //console.log("Vessel data on date change:", vesselData);
    //}, [vesselData]);

    // useEffect(()=>{
    //     console.log("from use effect in booking table, selectedDate: ", selectedDate)
    // },[selectedDate])

    const {bookingResources, fetchBookingResources} = useOperationsMapBookingResources(token)
    const { setBookingResources } = useBookingResourcesContext();
    useRefreshToken();

    const handleSelect = (booking) => {
        setSelectedBooking(booking);
        setShowOperationsMapBookingsUpdateModal(true);
    };

    const clearSelections = () => {
        setSelectedBooking(null);
    };

    const closeModal = (selectedDate) => {
        setShowOperationsMapBookingsUpdateModal(false);
        setShowOperationsMapAddNewBookingModal(false);
        handleDateChange(
            selectedDate,
            userRole,
            userPortId,
            setBookings,
            setSelectedDate,
            fetchVesselImages,
            fetchBookingResources,
            setVesselData,
            setVesselImageOrientation,
            token,
            setTide,
            setBookingResources
        )
        console.log("from close modal, selectedDate: ", selectedDate)
    };

    const columns = useMemo(() => Columns({ handleSelect }), [])
    const data = bookings
    console.log("From Operations map booking table, here are the bookings: ", bookings);

    const {
        getTableProps,
        getTableBodyProps,
        rows,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    return (

        <div className='operationsPictureBookingTopContainer'>
            <div>
                <div className='operationsPictureBookingTopContainerInside'>
                    <button className="btn today"
                        onClick={() => handleDateChange(
                            new Date(),
                            userRole,
                            userPortId,
                            setBookings,
                            setSelectedDate,
                            fetchVesselImages,
                            fetchBookingResources,
                            setVesselData,
                            setVesselImageOrientation,
                            token,
                            setTide,
                            setBookingResources
                        )}>
                        Today
                    </button>
                    <label className="bookingLabels" htmlFor="selectedDate">Select Date:</label>
                    <DateTime
                        className='selectedDate'
                        value={selectedDate}
                        onChange={(date) => handleDateChange(
                            date,
                            userRole,
                            userPortId,
                            setBookings,
                            setSelectedDate,
                            fetchVesselImages,
                            fetchBookingResources,
                            setVesselData,
                            setVesselImageOrientation,
                            token,
                            setTide,
                            setBookingResources
                        )}
                        inputProps={{ placeholder: 'Select Date', readOnly: true }}
                        timeFormat={false}
                        closeOnSelect={true}
                    />
                    <button data-html2canvas-ignore className="btn addToReports" onClick={() => handleAddToReports(setReportImages)}><FaFolderPlus className="addToReportsIcon" /></button>
                    <button className="btn addBooking" onClick={() => setShowOperationsMapAddNewBookingModal(true)}><FaRegSquarePlus className='addBookingIcon' /></button>
                </div>
                <div className='operationsPictureBookingTopContainerInside'>

                    <div className='operationsPictureBookingTopContainerInterior'>
                    </div>

                    <div className='operationsPictureBookingTopContainerInterior'>
                        <>
                            {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/> */}
                        </>
                    </div>
                </div>
            </div>

            <div className='operationsPictureTableContainer'>
                <table className="table table-hover" {...getTableProps()}>
                    <thead>
                        {
                            headerGroups.map(headerGroup => {
                                const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                                return (
                                    <tr {...headerGroupProps} key={headerGroupKey}>
                                        {headerGroup.headers.map(column => {
                                            const { key: columnKey, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps());
                                            return (
                                                <th className='operationsPictureBookingTh' key={columnKey} {...columnProps}>
                                                    {column.render('Header')}
                                                    {headerGroup.headers[headerGroup.headers.length - 1] !== column && (
                                                        <span style={{ marginLeft: '5px' }}> <FaSort /> </span>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            page.map(row => {
                                prepareRow(row);
                                const { key, ...rowProps } = row.getRowProps();
                                return (
                                    <tr key={row.id} {...rowProps} onClick={() => handleSelect(row.original)}>
                                        {row.cells.map(cell => {
                                            const { key, ...cellProps } = cell.getCellProps();
                                            return (
                                                <td key={key} className='operationsPictureBookingTd' {...cellProps}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>

            </div>
            {showOperationsMapBookingsUpdateModal && (
                <OperationsMapBookingsUpdateModal
                    booking={selectedBooking}
                    closeModal={closeModal}
                    clearSelections={clearSelections}
                />
            )}
            {showOperationsMapAddNewBookingModal && (
                <OperationsMapAddNewBookingModal
                    closeModal={closeModal}
                />
            )}
        </div>
    );
}

export default OperationsMapBookingTable;