import React, { createContext, useState, useContext } from 'react';

const TideContext = createContext();

const TideProvider = ({ children }) => {
    const [tide, setTide] = useState([]); 

    return (
        <TideContext.Provider value={{ tide, setTide }}>
            {children}
        </TideContext.Provider>
    );
};

const useTideContext = () => {
    const context = useContext(TideContext);
    if (!context) {
        throw new Error('useTideContext must be used within a TideProvider');
    }
    return context;
};

export { TideProvider, useTideContext };