import React, { useState, useEffect } from 'react';
import UserRoleContext from './UserRoleContext';

const UserRoleProvider = ({ children }) => {
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const storedRole = localStorage.getItem('userRole') || sessionStorage.getItem('userRole');
        if (storedRole) {
            setUserRole(storedRole);
        }
    }, []);

    return (
        <UserRoleContext.Provider value={{ userRole, setUserRole }}>
            {children}
        </UserRoleContext.Provider>
    );
};

export default UserRoleProvider;