import React from 'react';
import Constants from '../../Constants';
import { FaEdit, FaTrash } from "react-icons/fa";

const formattedDateTime = (dateTimeString) => {
    return new Date(dateTimeString).toLocaleString(undefined, { dateStyle: 'long', timeStyle: 'short' });
  };

  const formattedTime = (value) => {
    const date = new Date(value);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  

export const Columns = ({ }) => [

  {
    Header: 'Vessel',
    accessor: 'vesselName',
  },
  {
    Header: 'Mooring Time',
    accessor: 'mooringDateTime',
    Cell: ({ cell: { value } }) => formattedTime(value),
  },
  {
    Header: 'Departure Time',
    accessor: 'departureDateTime',
    Cell: ({ cell: { value } }) => formattedTime(value),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ cell: { value } }) => (
        <span className={`badge badge-pill badge-${value === 'CONFIRMED' ? 'success' : value === 'TENTATIVE' ? 'warning' : 'danger'}`}>
          {value}
        </span>
      ),
  },
  {
    Header: 'Berth',
    accessor: 'berthName',
  },

];


