import React, { useState, useEffect } from 'react';
import ReportImagesContext from './ReportImagesContext';

const ReportImagesProvider = ({ children }) => {
    //const [reportImages, setReportImages] = useState([]); 

    const [reportImages, setReportImages] = useState(() => {
        const savedImages = sessionStorage.getItem('reportImages');
        return savedImages ? JSON.parse(savedImages) : [
            // 'https://via.placeholder.com/150',
            // 'https://via.placeholder.com/150?text=Test'
        ];
    });

    useEffect(() => {
        sessionStorage.setItem('reportImages', JSON.stringify(reportImages));
    }, [reportImages]);

    return (
        <ReportImagesContext.Provider value={{ reportImages, setReportImages }}>
            {children}
        </ReportImagesContext.Provider>
    );
};

export default ReportImagesProvider;
