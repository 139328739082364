export const checkAuthenticateIssue = (error) => {

    console.error('Error fetching bookings:', error);
    console.error('Status:', error.status);

    if (error.status === 401) {
        alert("Login is no longer valid ("+error.status+"), please re-authenticate.");
        return true;
    }else{
        alert("Error: ("+error.status+"), please re-authenticate.");
        return true;
    }

};