import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import useOperationsMapBookingResources from './useOperationsMapBookingResources';

const OperationsMapShowGangwayReservationModal = ({ closeModal, selectedReservation }) => {

  const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
  const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
  const timestamps = selectedReservation.y || [];
  const startDate = timestamps[0] ? new Date(timestamps[0]) : null;
  const endDate = timestamps[1] ? new Date(timestamps[1]) : null;
  // const formattedStartDate = startDate ? startDate.toLocaleString() : 'N/A';
  // const formattedEndDate = endDate ? endDate.toLocaleString() : 'N/A';

  const [reservationDateError, setReservationDateError] = useState(false);

  const [formData, setFormData] = useState({
    id: '',
    bookingId: '',
    startDateTime: '',
    endDateTime: '',
    resourceType: ''
  });

  const {
    updateGangwayReservation,
    fetchBookingResources,
    handleRemoveBookingResource
  } = useOperationsMapBookingResources(token)


  useEffect(() => {
    setFormData({
      id: selectedReservation.id,
      gangwayId: selectedReservation.gangwayId,
      bookingId: selectedReservation.bookingId,
      startDateTime: moment(selectedReservation.y[0]).format(),
      endDateTime: moment(selectedReservation.y[1]).format(),
      resourceType: 'Gangway'
    });
  }, [selectedReservation]);

  const handleDateChange = (date, id) => {
    const localDate = moment(date).format();

    setFormData(prevFormData => {
      const newFormData = { ...prevFormData, [id]: localDate };
      //console.log("The old form data is: ", { prevFormData });
      //console.log("The new form data is: ", { newFormData });
      if (newFormData.startDateTime && newFormData.endDateTime) {
        setReservationDateError(moment(newFormData.startDateTime).isSameOrAfter(moment(newFormData.endDateTime)));
      }
      return newFormData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log("Here is the selectedReservation: ", selectedReservation)
    const formattedDates = {
      ...formData,
      vesselDoorId: selectedReservation.vesselDoorId,
      startDateTime: formData.startDateTime ? moment(formData.startDateTime).format() : null,
      endDateTime: formData.endDateTime ? moment(formData.endDateTime).format() : null,
    };
    console.log("formated dates: ", formattedDates);
    updateGangwayReservation(formattedDates, closeModal)
      .then(() => {
        fetchBookingResources(formattedDates.bookingId);
      })
      .catch(error => {
        console.error("Error updating gangway reservation:", error.response.data);
      });
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className='row gx-0'>
          <div className="text-center" style={{ width: 150, marginRight: '10px', marginLeft: '10px' }}>
            <label className="modalLables" htmlFor="contactDate">Start Time <span className="required">*</span></label>
            <DateTime
              dateFormat={false}
              className="form-control modalDisplay"
              value={formData.startDateTime ? moment(formData.startDateTime) : ''}
              selected={moment(formData.startDateTime).format()}
              onChange={date => handleDateChange(date, "startDateTime")}
            />
          </div>

          <div className='text-center' style={{ width: 150 }}>
            <label className="modalLables" htmlFor="contactDate">End Time <span className="required">*</span></label>
            <DateTime
              dateFormat={false}
              className="form-control modalDisplay"
              value={formData.endDateTime ? moment(formData.endDateTime) : ''}
              selected={moment(formData.endDateTime).format()}
              onChange={date => handleDateChange(date, "endDateTime")}
            />
          </div>
        </div>
        <div className='text-center' style={{ width: 330 }}>
          <button className="btn gangSave" type="submit" >Save</button>
          <button className="btn gangClose" type="button"  onClick={closeModal}>Close</button>
          <button className="btn gangDelete" type="button"  onClick={() => handleRemoveBookingResource(selectedReservation.bookingId, selectedReservation.vesselDoorId, selectedReservation.gangwayId)}>Delete</button>
        </div>

      </form>

    </div>
  );
};

export default OperationsMapShowGangwayReservationModal;
