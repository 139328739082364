import React, { useRef, useState } from 'react';
import { Line, Rect, Circle, Group } from 'react-konva';

export const ShorePower = () => {
    const [selectedGroup, setSelectedGroup] = useState(null);
    const shapeRef = useRef();
    const shapeRef1 = useRef();
    const groupRef = useRef();
    const transformerRef = useRef();

    const handleSelect = (lineRef) => {
        if (selectedGroup) {
            setSelectedGroup(null);
        } else {
            setSelectedGroup(lineRef);
        }
    };

    const handleDeselect = () => {
        setSelectedGroup(null);
    };

    const handleDragEnd = (e) => {
        const shape = shapeRef.current;
        setSelectedGroup(null);
    };

    const handleTransformEnd = () => {
        setSelectedGroup(null);
    };
    //group dimensions
    const circleSize = 10;
    const circleStartX = 1280;
    const circleStartY = 530;
    const rectWidth = circleSize * 10;
    const rectHeight = circleSize / 2;

    const generateBolt = (startX, startY) => {
        const boltWidth = circleSize;
        const boltHeight = circleSize * 2;


        //adjust bolt with these values
        const tipsXValue = boltWidth / 10
        const tipsYValue = boltHeight / 2.4
        const horizontalLinesXValue = boltWidth / 2
        const horizontalLinesYValue = boltHeight / 14

        const boltPoints = [
            startX + (tipsXValue), startY - (tipsYValue), //1
            startX, startY - (horizontalLinesYValue), //2
            startX + (horizontalLinesXValue), startY - (horizontalLinesYValue), //3
            startX - (tipsXValue), startY + (tipsYValue), //4
            startX, startY + (horizontalLinesYValue), //5
            startX - (horizontalLinesXValue), startY + (horizontalLinesYValue), //6
        ];

        return (
            <Line
                points={boltPoints}
                stroke="black"
                closed='true'
                strokeWidth={1}
            />
        )
    }
    return (
        <>
            <Group
                ref={groupRef}
                //draggable
                onDragEnd={handleDragEnd}
                onClick={() => handleSelect(groupRef)}
                //opacity={0.7}
            >
                <Rect
                    x={circleStartX + circleSize}
                    y={circleStartY}
                    width={rectWidth/2}
                    height={rectHeight}
                    fill="yellow"
                    //stroke="black" // Border color
                    //strokeWidth={1} // Border width
                />
                    <Rect
                    x={circleStartX - (rectWidth /2) - circleSize}
                    y={circleStartY}
                    width={rectWidth/2}
                    height={rectHeight}
                    fill="yellow"
                    //stroke="black" // Border color
                    //strokeWidth={1} // Border width
                />
                <Circle
                    x={circleStartX}
                    y={circleStartY}
                    radius={circleSize}
                    fill="yellow"
                   // stroke="black" // Border color
                   // strokeWidth={1} // Border width
                />
                {generateBolt(circleStartX, circleStartY)}
            </Group>


            {/* {selectedGroup && (
                <Transformer
                    ref={transformerRef}
                    nodes={[selectedGroup.current]}
                    boundBoxFunc={(oldBox, newBox) => {
                        return newBox;
                    }}
                    onTransformEnd={handleTransformEnd}
                />
            )} */}
        </>
    );
};
