import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import Constants from '../../Constants';
import '../../App.css'
import './Vessel.css'
import {Link, useLocation, useParams} from 'react-router-dom';
import DateTime from "react-datetime";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {checkAuthenticateIssue} from "../Login/LoginUtil";

function VesselDoorDetails() {
    const {vesselDoorIdParam} = useParams();
    const {vesselIdParam} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {vesselDoor} = location.state || {};

    const [selectedVesselDoor, setSelectedVesselDoor] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(true);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});

    const [debugging, setDebugging] = useState(false);

    const sides = [
        {
            name: 'Port'
        },
        {
            name: 'Starboard'
        }
    ]

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = 'Name is required';
        }
        if (!formData.side) {
            newErrors.side = 'Side is required';
        }
        if (!formData.height) {
            newErrors.height = 'Height is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const [formData, setFormData] = useState({
        id: 0,
        vesselId: 0,
        name: '',
        side: "",
        height: 0
    });

    useEffect(() => {

        if (vesselDoorIdParam == 'add') {
            if(debugging) window.alert("have to add one")
            setLoading(false);
            setUpdating(false);
            setFormData({vesselId: vesselIdParam});
        } else {


            axios.get(Constants.BACK_END + `/api/VesselDoor/${vesselIdParam}/${vesselDoorIdParam}`)
                .then(response => {
                    console.log('Data fetched:', response.data); // Log the response data
                    const vesselDoor = response.data;
                    setSelectedVesselDoor(vesselDoor);
                    setFormData({
                        id: vesselDoor.id,
                        vesselId: vesselDoor.vesselId,
                        name: vesselDoor.name,
                        side: vesselDoor.side,
                        height: vesselDoor.height
                    });
                    setLoading(false);
                })
                .catch(error => {
                    console.error('There was an error fetching the data!', error);
                    setError(error);
                    setLoading(false);
                    if(checkAuthenticateIssue(error)){
                        navigate("/Login")
                    };
                });
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleChange = (e) => {
        //window.alert("Handling a change");
        const {id, value} = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [id]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // if (!formData || !formData.name) {
        //     console.error('Please complete all fields');
        //     window.alert('Please complete all fields');
        //     return;
        // }
        console.log('Form Data:', selectedVesselDoor);
        if (validateForm()) {
            if (updating) {
                if (debugging) window.alert("about to put");
                axios.put(Constants.BACK_END + `/api/VesselDoor`, formData)
                    .then(response => {
                        console.log('Updated successfully', response.data);
                        //window.alert('Updated successfully'); // Display pop-up message
                        toast.success('Vessel Door Updated.', {
                            position: "top-center",
                            hideProgressBar: true
                        });

                    })
                    .catch(error => {
                        console.error('Error updating: ' + JSON.stringify(selectedVesselDoor), error);
                        toast.error('Error updating: ' + error.response.data.message || error.message, {
                            position: "top-center",
                            hideProgressBar: true
                        });
                    });
            } else {
                if (debugging) window.alert("about to post");
                axios.post(Constants.BACK_END + `/api/VesselDoor`, formData)
                    .then(response => {
                        console.log('Added successfully', response.data);
                        window.alert('Added successfully'); // Display pop-up message
                        setUpdating(true);
                        navigate(`/VesselDetails/${vesselIdParam}`);
                    })
                    .catch(error => {
                        toast.error('Error adding: ' + error.response.data.message || error.message, {
                            position: "top-center",
                            hideProgressBar: true
                        });
                        console.error('Error adding: ' + JSON.stringify(selectedVesselDoor), error);
                        //window.alert('Error adding' + JSON.stringify(selectedVesselDoor));
                    });
            }
        }else{
            toast.error('Error - please enter all required fields', {
                position: "top-center",
                hideProgressBar: true
            });
        }
    };


    return (
        <div>

            <div className="App">
                <header className="App-header">
                    <h1>Vessel Door</h1>
                </header>
            </div>

            <form className="row g-1" id="updateForm" onSubmit={handleSubmit}>
                <div className="col-md-6 px-3">
                    <label htmlFor="name">Name<span className="required">*</span></label>
                    <input
                        type="hidden"
                        className="form-control"
                        id="id"
                        type="hidden"
                        value={formData.id}
                    />
                    <input
                        type="hidden"
                        className="form-control"
                        id="vesselId"
                        type="hidden"
                        value={formData.vesselId}
                    />
                    <input
                        type="text"
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        id="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>

                <div className="col-md-6 px-3">
                    <label htmlFor="side">Side <span className="required">*</span></label>
                    <select
                        className={`form-control ${errors.side ? 'is-invalid' : ''}`}
                        id="side"
                        value={formData.side}
                        onChange={handleChange}
                    >
                        <option value="">Select Side</option>
                        {sides.map((side, index) => (
                            <option key={index} value={side.name}>
                                {side.name}
                            </option>
                        ))}
                    </select>
                    {errors.side && <div className="invalid-feedback">{errors.side}</div>}
                </div>


                <div className="col-md-6 px-3">
                    <label htmlFor="height">Height<span className="required">*</span></label>
                    <input
                        type="number"
                        className={`form-control ${errors.height ? 'is-invalid' : ''}`}
                        id="height"
                        placeholder="Height"
                        value={formData.height}
                        onChange={handleChange}
                    />
                </div>

                {/*<div class="container">*/}
                <div class="row">
                    <div className="">
                        <button type="submit"
                                className="col-md-12 btn btn-primary button-margin">{updating ? 'Update' : 'Add'}</button>
                        <Link
                            to={{
                                pathname: `/VesselDetails/${vesselIdParam}`,
                                state: {vesselDoor}
                            }}
                        >
                            <button className="col-md-12 btn btn-warning button-margin">Return</button>
                        </Link>
                        <ToastContainer/>
                    </div>
                </div>
                {/*</div>*/}
            </form>
        </div>

    );
}

export default VesselDoorDetails;


