import React, { useState } from 'react';
import BookingResourcesContext from './BookingResourcesContext';

const BookingResourcesProvider = ({ children }) => {
    const [bookingResources, setBookingResources] = useState([]); 

    return (
        <BookingResourcesContext.Provider value={{ bookingResources, setBookingResources }}>
            {children}
        </BookingResourcesContext.Provider>
    );
};

export default BookingResourcesProvider;
