import React, { useRef, useState } from 'react';
import { Line, Transformer, Rect, Group } from 'react-konva';

export const Gangway = () => {
  const [selectedLine, setSelectedLine] = useState(null);
  const shapeRef = useRef();
  const shapeRef1 = useRef();
  const groupRef = useRef();
  const transformerRef = useRef();

  const handleSelect = (lineRef) => {
    if (selectedLine) {
      setSelectedLine(null);
    } else {
      setSelectedLine(lineRef);
    }
  };

  const handleDeselect = () => {
    setSelectedLine(null);
  };

  const handleDragEnd = (e) => {
    const shape = shapeRef.current;
    setSelectedLine(null);
  };

  const handleDragEnd1 = (e) => {
    const shape = shapeRef1.current;
    setSelectedLine(null);
  };

  const handleDragEnd2 = (e) => {
    const shape = groupRef.current;
    setSelectedLine(null);
  };

  const handleTransformEnd = () => {
    setSelectedLine(null);
  };
  //group dimensions
  const squareSize = 11;
  const squareStartX = 1340;
  const squareStartY = 460;
  const generateLines = (startX, startY) => {
    const linesData = [
      //make X
      { points: [startX + 2, startY + 2, startX + squareSize - 2, startY + squareSize - 2] },
      { points: [startX + squareSize - 2, startY + 2, startX + 2, startY + squareSize - 2] },
      //make horizontal lines on either side of box
      { points: [startX - (squareSize / 2), startY + (squareSize / 2), startX, startY + (squareSize / 2)] },
      { points: [startX + squareSize, startY + (squareSize / 2), startX + (squareSize * 1.5), startY + (squareSize / 2)] },
      //make  end vertical line of (my) left side of box
      { points: [startX - (squareSize / 2), startY, startX - (squareSize / 2), startY + (squareSize)] },
      //make  end vertical line of (my) right side of box
      { points: [startX + (squareSize * 1.5), startY, startX + (squareSize * 1.5), startY + (squareSize)] },
      //make  two vertical line of (my) left side of box
      { points: [startX - (squareSize / 3), startY + (squareSize / 4), startX - (squareSize / 3), startY + (squareSize * 3 / 4)] },
      { points: [startX - (squareSize / 6), startY + (squareSize / 4), startX - (squareSize / 6), startY + (squareSize * 3 / 4)] },
      //make  two vertical line of (my) right side of box
      { points: [startX + (squareSize * 4 / 3), startY + (squareSize / 4), startX + (squareSize * 4 / 3), startY + (squareSize * 3 / 4)] },
      { points: [startX + (squareSize * 7 / 6), startY + (squareSize / 4), startX + (squareSize * 7 / 6), startY + (squareSize * 3 / 4)] },
    ];

    return linesData.map((line, index) => (
      <Line
        key={index}
        points={line.points}
        stroke="black"
        strokeWidth={1}
      />
    ))
  }

  return (
    <>
      <Group
        ref={groupRef}
        draggable
        onDragEnd={handleDragEnd2}
        onClick={() => handleSelect(groupRef)}
      >
        <Rect
          x={squareStartX}
          y={squareStartY}
          width={squareSize}
          height={squareSize}
          fill="red"
        />
        {generateLines(squareStartX, squareStartY)}
      </Group>


      {/* Line 1 */}
      <Line
        ref={shapeRef}
        points={[1300, 460, 1300, 483]} // Start and end points of the line [x1, y1, x2, y2]
        stroke="blue"
        strokeWidth={4}
        draggable
        onClick={() => handleSelect(shapeRef)}
        onDragEnd={handleDragEnd}
      />

      {/* Line 2 */}
      <Line
        ref={shapeRef1}
        points={[1310, 460, 1310, 483]}
        stroke="blue"
        strokeWidth={4}
        draggable
        onClick={() => handleSelect(shapeRef1)}
        onDragEnd={handleDragEnd1}
      />



      {selectedLine && (
        <Transformer
          ref={transformerRef}
          nodes={[selectedLine.current]}
          boundBoxFunc={(oldBox, newBox) => {
            return newBox;
          }}
          onTransformEnd={handleTransformEnd}
        />
      )}
    </>
  );
};
