import 'bootstrap/dist/css/bootstrap.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Constants from '../../Constants';
import moment from 'moment';
import 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserRoleContext from '../../Contexts/UserRoleContext';
import {checkAuthenticateIssue} from "../Login/LoginUtil";
import {useNavigate} from "react-router-dom";
import { berthByFactor } from './OperationsMapBerthByFactor';
import { useSelectedDateContext } from "../../Contexts/useSelectedDateContext";

function OperationsMapAddNewBookingModal({ closeModal, fetchBookingListThisYear }) {
    const [formData, setFormData] = useState({
        vesselName: '',
        vesselOrientation:'',
        mooringDateTime: '',
        departureDateTime: '',
        status: '',
        cancellationReason: '',
        specialNote: '',
        contactDateTime: '',
        agent: '',
        portId: '',
        berthName: '',
        xCoordinateFactor: '',
        yCoordinateFactor: ''
    });

    const {selectedDate, setSelectedDate} = useSelectedDateContext();

    const [vesselNames, setVesselNames] = useState([]);
    const [berthNames, setBerthNames] = useState([]);
    const [bookingDateError, setBookingDateError] = useState(false);
    const [errors, setErrors] = useState({});

    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
    const { userRole } = useContext(UserRoleContext);
    const navigate = useNavigate();

    var yesterday = moment().subtract(1, 'day');
    var valid = function (current) {
        return current.isAfter(yesterday);
    }
    const modalRef = useRef(null);
    useEffect(() => {
        let handler = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                closeModal(selectedDate)
            }
        };
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [closeModal]);

    useEffect(() => {
        axios.get(`${Constants.BACK_END}/api/Berth/${userPortId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setBerthNames(response.data);
            })
            .catch(error => {
                console.error('Error fetching berth names:', error);
                if(checkAuthenticateIssue(error)){
                    navigate("/Login")
                };
            });
    }, [userPortId, token]);

    useEffect(() => {
        axios.get(`${Constants.BACK_END}/api/Vessel`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setVesselNames(response.data);
            })
            .catch(error => {
                console.error('Error fetching vessel names:', error);
                if(checkAuthenticateIssue(error)){
                    navigate("/Login")
                };
            });
    }, [token]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [id]: value.slice(0, 500)
        }));
    };

    const handleDateChange = (date, id) => {
        const localDate = date ? moment(date).format() : '';
        setFormData(prevFormData => {
            const newFormData = { ...prevFormData, [id]: localDate };

            if (newFormData.mooringDateTime && newFormData.departureDateTime) {
                setBookingDateError(moment(newFormData.mooringDateTime).isSameOrAfter(moment(newFormData.departureDateTime)));
            }
            return newFormData;
        });
    };

    const getCoordinatesByBerth = (berthName) => {
        if (berthByFactor[berthName]) {
            const [xCoordinateFactor, yCoordinateFactor] = berthByFactor[berthName]
            return {
                xCoordinateFactor: xCoordinateFactor.xCoordinateFactor,
                yCoordinateFactor: yCoordinateFactor.yCoordinateFactor
            };
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (!["PortAdmin"].includes(userRole)) {
        //     toast.error('You do not have the necessary permissions to add this booking.', {
        //         position: "top-center",
        //         hideProgressBar: true
        //     });
        //     return;
        // }
        if (validateForm()) {
           const { xCoordinateFactor, yCoordinateFactor } = getCoordinatesByBerth(formData.berthName);
            const updatedFormData = { 
                ...formData, 
                portId: userPortId, 
                xCoordinateFactor, 
                yCoordinateFactor 
            };
            console.log("updatedFormData: ", updatedFormData);
            axios.post(Constants.BACK_END + '/api/Booking', updatedFormData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log('Booking added successfully', response.data);
                    toast.success('Booking added successfully', {
                        position: "top-center",
                        hideProgressBar: true
                    });
                    setTimeout(() => {
                        closeModal(selectedDate);
                    }, 1500);
                })
                .catch(error => {
                    let errorMessage = 'An unknown error occurred.';
                    if (error.response) {
                        errorMessage = error.response.data || 'An error occurred while adding the booking.';
                    } else if (error.request) {
                        errorMessage = 'No response received from the server.';
                    } else {
                        errorMessage = error.message;
                    }
                    toast.error(`Error adding booking: ${errorMessage}`, {
                        position: "top-center",
                        hideProgressBar: true,
                    });
                    console.error('Error adding booking:', error);
                });
                
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.vesselName) { newErrors.vesselName = 'Vessel Name is required'; }
        if (!formData.mooringDateTime) { newErrors.mooringDateTime = 'Mooring Date and Time is required'; }
        if (!formData.departureDateTime) { newErrors.departureDateTime = 'Departure Date and Time is required'; }
        if (!formData.contactDateTime) { newErrors.contactDateTime = 'Contact Date and Time is required'; }
        if (!formData.berthName) { newErrors.berthName = 'Berth Name is required'; }
        if (!formData.status) { newErrors.status = 'Status is required'; }
        if (!formData.vesselOrientation) { newErrors.vesselOrientation = 'Orientation is required'; }

        if (bookingDateError) {
            console.log('error found in times');
            newErrors.departureDateTime2 = 'Departure Date and Time must be after Mooring Date and Time';
            console.log(newErrors.departureDateTime2);
        } else {
            console.log('error NOT found in times');
            console.log(newErrors.departureDateTime2);
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <div className="modal-container" >
            {/* <ToastContainer /> */}
            <form ref={modalRef} className="row g-3" id="bookingsUpdateModal" onSubmit={handleSubmit}>
                {bookingDateError && (
                    <div className="col-md-12">
                        <div className="alert alert-danger" role="alert">
                            Mooring date and time must be before departure date and time.
                        </div>
                    </div>
                )}

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="agent">Agent</label>
                    <input
                        type="text"
                        className="form-control"
                        id="agent"
                        placeholder="Agent"
                        value={formData.agent}
                        onChange={handleChange}
                    />
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="contactDateTime">Contact Date <span className="required">*</span></label>
                    <DateTime
                        className={`form-control ${errors.contactDateTime ? 'is-invalid' : ''}`}
                        value={formData.contactDateTime ? moment(formData.contactDateTime) : ''}
                        onChange={date => handleDateChange(date, "contactDateTime")}
                    />
                    {errors.contactDateTime && <div className="invalid-feedback">{errors.contactDateTime}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="vesselName">Vessel Name<span className="required">*</span></label>
                    <select
                        className={`form-control ${errors.vesselName ? 'is-invalid' : ''}`}
                        id="vesselName"
                        value={formData.vesselName}
                        onChange={handleChange}
                    >
                        <option value="">Select Vessel</option>
                        {vesselNames.map((vessel, index) => (
                            <option key={index} value={vessel.name}>
                                {vessel.name}
                            </option>
                        ))}
                    </select>
                    {errors.vesselName && <div className="invalid-feedback">{errors.vesselName}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="berthName">Berth Name <span className="required">*</span></label>
                    <select
                        className={`form-control ${errors.berthName ? 'is-invalid' : ''}`}
                        id="berthName"
                        value={formData.berthName}
                        onChange={handleChange}
                    >
                        <option value="">Select Berth</option>
                        {berthNames.map((name, index) => (
                            <option key={index} value={name}>
                                {name}
                            </option>
                        ))}
                    </select>
                    {errors.berthName && <div className="invalid-feedback">{errors.berthName}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="vesselOrientation">Orientation <span className="required">*</span></label>
                    <select
                        className={`form-control ${errors.vesselOrientation ? 'is-invalid' : ''}`}
                        id="vesselOrientation"
                        value={formData.vesselOrientation}
                        onChange={handleChange}
                    >
                        <option value="">Select Orientation</option>
                        <option value="PORT">PORT</option>
                        <option value="STARBOARD">STARBOARD</option>
                    </select>
                    {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="status">Status <span className="required">*</span></label>
                    <select
                        className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                        id="status"
                        value={formData.status}
                        onChange={handleChange}
                    >
                        <option value="">Select Status</option>
                        <option>TENTATIVE</option>
                        <option>CONFIRMED</option>
                    </select>
                    {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="mooringDateTime">Mooring Date and Time <span className="required">*</span></label>
                    <DateTime
                        isValidDate={valid}
                        className={`form-control ${errors.mooringDateTime ? 'is-invalid' : ''}`}
                        value={formData.mooringDateTime ? moment(formData.mooringDateTime) : ''}
                        onChange={date => handleDateChange(date, "mooringDateTime")}
                    />
                    {errors.mooringDateTime && <div className="invalid-feedback">{errors.mooringDateTime}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="departureDateTime">Departure Date and Time <span className="required">*</span></label>

                    <DateTime
                        className={`form-control ${errors.departureDateTime ? 'is-invalid' : ''}`}
                        value={formData.departureDateTime ? moment(formData.departureDateTime) : ''}
                        onChange={date => handleDateChange(date, "departureDateTime")}
                    />
                    {errors.departureDateTime && <div className="invalid-feedback">{errors.departureDateTime}</div>}
                    {errors.departureDateTime2 && <div className="invalid-feedback">{errors.departureDateTime2}</div>}
                </div>



                {/* <div className="col-md-6">
                    <label className="modalLables" htmlFor="cancellationReason">Cancellation Reason</label>
                    <input
                        type="text"
                        className="form-control"
                        id="cancellationReason"
                        placeholder="Cancellation Reason"
                        value={formData.cancellationReason}
                        onChange={handleChange}
                    />
                </div> */}
                <div className="col-md-6">
                    <label className="modalLables" htmlFor="specialNote">Special Note (500 Characters)</label>
                    <textarea
                        type="text"
                        className="form-control"
                        id="specialNote"
                        placeholder="Special Note"
                        maxLength={500}
                        value={formData.specialNote}
                        onChange={handleChange}
                    />
                </div>

                {/* <div className="col-md-6">
                    <label className="modalLables" htmlFor="portName">Port Id</label>
                    <div id="portId">{userPortId}</div>
                </div> */}
               {/* {bookingDateError && (
                    <div className="col-md-12">
                        <div className="alert alert-danger" role="alert">
                            Mooring date and time must be before departure date and time.
                        </div>
                    </div>
                )} */}
                <button type="submit" className="btn btn-primary">Add New Booking</button>
                <button type="button" className="btn btn-warning" onClick={closeModal}>Cancel</button>
 
            </form>
        </div>
    );
}

export default OperationsMapAddNewBookingModal;
