import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from "recharts";
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import UserRoleContext from '../../Contexts/UserRoleContext';
import Constants from '../../Constants';
import moment from 'moment';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { useTideContext } from "../../Contexts/TideContext";

function OperationsMapTide() {

    const { tide, setTide } = useTideContext();

    const TideCustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const formattedDate = new Date(label).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
            const height = payload[0].value.toFixed(2);
            return (
                <div className="customTooltip">
                    {`Time: ${formattedDate}    `}
                    {`Height: ${height} m`}
                </div>
            );
        }
    }

    return (

            <div className='operationsMapTideTopContainer'>
                <label className="bookingLabels" htmlFor="selectedDate">Tide Data</label>
                <ResponsiveContainer width="100%" height={100}>
                    <LineChart data={tide}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="tideDateTime" tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} />
                        <YAxis dataKey="height" > </YAxis>
                        <Tooltip cursor={false} content={<TideCustomTooltip />} />
                        <Line type="monotone" dataKey="height" stroke="#22418a" dot={false} activeDot={{ r: 5 }} />
                    </LineChart>
                </ResponsiveContainer>
            </div>


    )
}
export default OperationsMapTide