import React, { useState, useEffect, useCallback } from 'react';
import { PDFViewer, Image, Page, View, Text, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { useReportImagesContext } from '../../Contexts/useReportImagesContext';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { debounce } from 'lodash';
import { FaTrashAlt, FaTrash } from "react-icons/fa";

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: '/fonts/Montserrat-Regular.ttf', fontWeight: 'normal' },
    {src: '/fonts/Montserrat-Bold.ttf', fontWeight: 'bold' },
    {src: '/fonts/Montserrat-Italic.ttf', fontStyle: 'italic' },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
  },
  header: {
    height: 60,
    backgroundColor: '#22418a', 
    padding: 10,
    position: 'relative',
  },
  date: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    color: 'white',
    textAlign: 'right',
    marginRight: 8,
    marginTop: 5,
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'white',
  },
  section: {
    margin: 10,
  },
  imageContainer: {
    top: 50,
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
  },
  image: {
    width: '90%',
    height: 'auto',
    padding: 10,
    objectFit: 'contain',
    border: '2px solid rgba(34, 65, 138, 1)',
    borderRadius: 10,
  },
  description: {
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    fontSize: 20,
    margin: 5,
    paddingLeft: 50,
    color: '#22418a',
  },
});

const ItemTypes = {
  IMAGE: 'image',
};

const DraggableImage = ({ image, index, moveImage, handleDeleteImage }) => {
  const [, ref] = useDrag({
    type: ItemTypes.IMAGE,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <li ref={(node) => ref(drop(node))} className='reportsImageCard'>
      <img
        src={image}
        alt={`Debug ${index}`}
        style={{ width: '200px', height: 'auto' }}
        className='reportsImageThumbnail' />
             <button
        onClick={() => handleDeleteImage(index)}
        className="btn delete">
        <FaTrashAlt />
      </button>
    </li>
  );
};

const MyDocument = ({ screenshots, updatedTitle, updatedDescription }) => {
  const [todayDate, setTodayDate] = useState('');

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    setTodayDate(formattedDate);
  }, []);

  return (
  <Document>

    <Page size="letter" orientation="landscape" style={styles.page}>

      <View style={styles.header}>
      <Text style={styles.date}>Generated on: {todayDate}</Text>
      <Text style={styles.title}>{updatedTitle}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.description}>{updatedDescription}</Text>
      </View>

      {screenshots.map((screenshot, index) => (
        <View key={index} style={styles.imageContainer}>
          <Image style={styles.image} src={screenshot} />
        </View>
      ))}
    </Page>
  </Document>
  );
};

const Reports = () => {
  const { reportImages } = useReportImagesContext();
  const [title, setTitle] = useState('');
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [description, setDescription] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('')
  const [screenshots, setScreenshots] = useState(reportImages);

  const moveImage = (fromIndex, toIndex) => {
    const updatedScreenshots = update(screenshots, {
      $splice: [[fromIndex, 1], [toIndex, 0, screenshots[fromIndex]]],
    });
    setScreenshots(updatedScreenshots);
  };

  const handleDeleteImage = (index) => {
    const updatedScreenshots = screenshots.filter((_, i) => i !== index);
    setScreenshots(updatedScreenshots);
  
    const storedImages = JSON.parse(sessionStorage.getItem('reportImages')) || [];
    const updatedStoredImages = storedImages.filter((_, i) => i !== index);
    sessionStorage.setItem('reportImages', JSON.stringify(updatedStoredImages));
  };

  const clearAllImages = () => {
    setScreenshots([]);  
    setUpdatedDescription([]);
    setUpdatedTitle([]);
    setTitle(''); 
    setDescription('');
    sessionStorage.removeItem('reportImages');  
  };

  useEffect(() => {
    setScreenshots(reportImages);
  }, [reportImages]);

  const debouncedUpdateTitle = useCallback(
    debounce((newTitle) => {
      setUpdatedTitle(newTitle);
    }, 1000),
    []
  );

  const debouncedUpdateDescription = useCallback(
    debounce((newDescription) => {
      setUpdatedDescription(newDescription);
    }, 1000),
    []
  );

  const handleTitleChange = (e) => {
    setTitle(e.target.value); 
    debouncedUpdateTitle(e.target.value); 
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value); 
    debouncedUpdateDescription(e.target.value);
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="reportsContainer col-md-12">
        <div className="reportsContainerInterior col-md-4">
          <div className="reportsContainerInputs">
            <h2 className="reportsHeader">Report Details:</h2>
            <input
              className="reportsContainerTextInput"
              type="text"
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter report title"
            />
            <textarea
              className="reportsContainerTextInput"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Enter report description"
            />
          </div>
          <h2 className="reportsHeader">Current Images:</h2>
          <ul>
            {screenshots.map((image, index) => (
              <DraggableImage 
              key={index} 
              image={image} 
              index={index} 
              moveImage={moveImage} 
              handleDeleteImage={handleDeleteImage}
              />
            ))}
          </ul>
          <button onClick={clearAllImages} className="btn today">
            <FaTrash /> Clear Report
          </button>
        </div>

        <div className="reportsContainerInterior col-md-8">
          <h2 className="reportsHeader">Report:</h2>
          <PDFViewer 
            width="80%"
            height="800px">
            <MyDocument
              screenshots={screenshots}
              updatedTitle={updatedTitle}
              updatedDescription={updatedDescription}
               />
          </PDFViewer>
        </div>
      </div>
    </DndProvider>
  );
};

export default Reports;
