
export const getColorBasedOnValue = (value) => {
    if (value >= 30) {
      return 'lightcoral';
    } else if (value >= 20 && value < 30) {
      return 'yellow';
    } else if (value > -20 && value < 20) {
      return 'lightgreen';
    } else if (value <= -20 && value > -30) {
      return 'yellow';
    } else {
      return 'lightcoral';
    }
  };
  