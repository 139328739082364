import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { ComposedChart, Bar, BarChart, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from "recharts";
import "react-datetime/css/react-datetime.css";
import UserRoleContext from '../../Contexts/UserRoleContext';
import Constants from '../../Constants';
import 'moment-timezone';
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OperationsDateTide from './OperationsDateTide';
import OperationsGangwayBerth from './OperationsGangwayBerth';
import OperationsBookingResources from './OperationsBookingResources';
import OperationsGraphModal from './OperationsGraphModal';
import { getColorBasedOnValue } from "../OperationsMap/OperationsMapGetColorUtil";
import {CustomTooltip} from "../OperationsMap/OperationsMapCustomToolTip";
import { useBookingResourcesContext } from "../../Contexts/useBookingResourcesContext";
import html2canvas from 'html2canvas';
import { useReportImagesContext } from '../../Contexts/useReportImagesContext';
import { FaFolderPlus } from "react-icons/fa";


function Operations() {
    const [bookings, setBookings] = useState([]);
    const [tide, setTide] = useState([]);
    const [slopeData, setSlopeData] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [vesselData, setVesselData] = useState(null);
    const { userRole } = useContext(UserRoleContext);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [vesselDoors, setVesselDoors] = useState({});
    const [selectedSide, setSelectedSide] = useState({});

    const [selectedBerths, setSelectedBerths] = useState({});
    const [doorGangwaySelection, setDoorGangwaySelection] = useState({});
    const [showDivs, setShowDivs] = useState({});
    const [doorSlopeData13M, setDoorSlopeData13M] = useState({});
    const [doorSlopeData7M, setDoorSlopeData7M] = useState({});
    const [doorSlopeData425M, setDoorSlopeData425M] = useState({});
    const [calculationError, setCalculationError] = useState({});

    const [showGangwayReservationModal, setShowGangwayReservationModal] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const [selectedGangwayId, setSelectedGangwayId] = useState(null);
    const [selectedMooringDateTime, setSelectedMooringDateTime] = useState(null);
    const [selectedDepartureDateTime, setSelectedDepartureDateTime] = useState(null);

    const [showGraphModal, setShowGraphModal] = useState(false);
    const [modalVesselDoor, setModalVesselDoor] = useState({});
    const [modalBooking, setModalBooking] = useState({});
    const [modalSlopeData, setModalSlopeData] = useState({});
    const [modalGangwayId, setModalGangwayId] = useState({});
    const [modalGangwayName, setModalGangwayName] = useState({});
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");

    const [modalClosed, setModalClosed] = useState(false);
    const { bookingResources, setBookingResources } = useBookingResourcesContext();

    const handleError = (error, context) => {
        const status = error.response?.status;
        if (status === 401) {
            toast.error('Your session has expired. Please log in again.', {
                position: "top-center",
                hideProgressBar: true
            });
        } else {
            console.error(`Error in ${context}:`, error);
            const errorMessage = error.response?.data?.message || 'An error has occurred';
            toast.error(`Error in ${context}: ${errorMessage}`);
        }
    };

    const { gangwayInformation, berthInformation } = OperationsGangwayBerth(userPortId, token);
    const {
        handleRemoveBookingResource,
        handleSelectBookingResource,
        handleUpdateBookingResource,
        fetchBookingResources,
        hasBookingResource
    } = OperationsBookingResources(token)

    useEffect(() => {

        if (selectedBooking && selectedDate && selectedBerths) {
            const vesselDoor = vesselDoors[selectedBooking.id];
            if (vesselDoor) {
                vesselDoor.filter(door => door.side === selectedSide[selectedBooking.id]).forEach(door => {
                    const gangwayId = doorGangwaySelection[selectedBooking.id]?.[door.id];
                    if (door.id && gangwayId) {
                        fetchSlopeData(
                            selectedBooking.mooringDateTime,
                            selectedBooking.departureDateTime,
                            door.id,
                            selectedSide,
                            selectedBerths[selectedBooking.id]
                        );
                    }
                });
            }
        }
    }, [selectedBerths, doorGangwaySelection])

    const handleSideChange = (e, booking) => {
        setSelectedSide(prevState => ({
            ...prevState,
            [booking.id]: e.target.value,
        }));
        setSelectedBooking(booking);
    }

    const handleBerthChange = (e, booking) => {
        setSelectedBerths(prevState => ({
            ...prevState,
            [booking.id]: e.target.value
        }));
        setSelectedBooking(booking);
    }

    const handleGangwayChange = (doorId, gangwayId, selectedBooking) => {
        setDoorGangwaySelection(prevState => ({
            ...prevState,
            [selectedBooking.id]: {
                ...prevState[selectedBooking.id],
                [doorId]: gangwayId
            }
        }));
        fetchSlopeData(
            gangwayId,
            selectedBooking.mooringDateTime,
            selectedBooking.departureDateTime,
            doorId,
            selectedSide,
            selectedBerths[selectedBooking.id]
        );
    };

    const handleDateChange = (formattedDate, data) => {
        fetchBookingListSelectedDate(formattedDate);
        setTide(data);
    }

    const handleGangwayReservation = (bookingId, gangwayId, mooringDateTime, departureDateTime) => {
        setSelectedBookingId(bookingId);
        setSelectedGangwayId(gangwayId);
        setShowGangwayReservationModal(true);
        setSelectedMooringDateTime(mooringDateTime);
        setSelectedDepartureDateTime(departureDateTime);
    };

    const fetchBookingListSelectedDate = (date) => {
        const apiUrlSelectedDate = `${Constants.BACK_END}/api/Booking/date/${date}/portId/${userPortId}`;
        axios.get(userRole === "PortUser" ? "" : apiUrlSelectedDate, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setBookings(response.data);
                response.data.forEach(booking => fetchVesselDoor(booking.vesselName, booking.id));
                response.data.forEach(booking => fetchBookingResources(booking.id))

                const berthDetails = response.data.reduce((acc, booking) => {
                    acc[booking.id] = booking.berthId;
                    return acc;
                }, {});
                setSelectedBerths(berthDetails);
                const sideDetails = response.data.reduce((acc, booking) => {
                    acc[booking.id] = booking.vesselOrientation;
                    return acc;
                }, {});
                setSelectedSide(sideDetails);
            })
            .catch(error => {
                handleError(error, 'Getting Bookings')
            });
    }

    const fetchSlopeData = (mooringDateTime, departureDateTime, doorId, vesselSide, berthId) => {
        // Helper function to fetch slope for each gangway
        const fetchSlopeForEachGangway = (gangwayId, setSlopeDataFunction) => {
            console.log("berthId: ", berthId)
            console.log("doorId: ", doorId)
            console.log("vessel side: ", vesselSide)
            console.log("mooringDateTime: ", mooringDateTime)
            console.log("departureDateTime: ", departureDateTime)
            const apiUrlGetSlope = `${Constants.BACK_END}/api/Calculations/slopes/gangwayId/${gangwayId}/mooringDateTime/${mooringDateTime}/departureDateTime/${departureDateTime}/doorId/${doorId}/vesselSide/${vesselSide}/berthId/${berthId}`;
            axios.get(apiUrlGetSlope, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    const processedData = response.data.map(item => ({
                        ...item,
                        slopeMultiples: [(item.slope +1) * 10000, (item.slope +1) * -10000]
                    }));
                    setCalculationError(prevState => ({
                        ...prevState,
                        [doorId]: null
                    }));
                    setSlopeDataFunction(prevState => ({
                        ...prevState,
                        [doorId]: processedData
                    }));
                })
                .catch(error => {
                    console.error('Error fetching slopes:', error);
                    setCalculationError(prevState => ({
                        ...prevState,
                        [doorId]: 'Error fetching slope data: ' + (error.response?.data?.message || 'Unknown error')
                    }));
                    setSlopeDataFunction(prevState => ({
                        ...prevState,
                        [doorId]: []
                    }));
                });
        };
       // console.log("berthId2: ", berthId)
        // If berthId is 4, set slope data to 0 and do not call the API
       // console.log(typeof berthId); // This will tell you if it's a string or a number

        if (berthId === '4') {
            const processedData = [{
                slope: 0,
                slopeMultiples: [0, 0] // Multiple slopes of 0
            }];
            setCalculationError(prevState => ({
                ...prevState,
                [doorId]: null
            }));
            // Set slope data for each gangway to 0
            setDoorSlopeData13M(prevState => ({
                ...prevState,
                [doorId]: processedData
            }));
            setDoorSlopeData7M(prevState => ({
                ...prevState,
                [doorId]: processedData
            }));
            setDoorSlopeData425M(prevState => ({
                ...prevState,
                [doorId]: processedData
            }));
        } else {
            // If berthId is not 4, proceed with the slope calculation
            fetchSlopeForEachGangway(3, setDoorSlopeData13M);
            fetchSlopeForEachGangway(2, setDoorSlopeData7M);
            fetchSlopeForEachGangway(1, setDoorSlopeData425M);
        }
    };
    
    

    const fetchVesselDoor = (vesselName, bookingId) => {
        const apiUrlSelectedVesselDoor = `${Constants.BACK_END}/VesselName/${vesselName}`;

        axios.get(apiUrlSelectedVesselDoor, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                const doors = response.data
                setVesselDoors(prevState => ({
                    ...prevState,
                    [bookingId]: doors
                }));
                setDoorGangwaySelection(prevState => {
                    const newSelection = { ...prevState };
                    doors.forEach(door => {
                        if (!newSelection[bookingId]) {
                            newSelection[bookingId] = {};
                        }
                        if (!newSelection[bookingId][door.id]) {
                            newSelection[bookingId][door.id] = gangwayInformation[0]?.id || '';
                        }
                    });
                    return newSelection;
                });
            })
            .catch(error => {
                console.error('Error fetching doors:', error);
            });
    }

    const showBookingDivs = useCallback((bookingId) => {
        setShowDivs(prevState => ({
            ...prevState,
            [bookingId]: !prevState[bookingId]
        }));
        const selectedBooking = bookings.find(booking => booking.id === bookingId)
        setSelectedBooking(bookingId);
        if (selectedBooking) {
            setSelectedBerths(prevState => ({
                ...prevState,
                [bookingId]: selectedBooking.berthId
            }));
        }
        if (vesselDoors[bookingId]) {
            vesselDoors[bookingId].forEach(door => {
                setSelectedBooking(bookingId);
                fetchSlopeData(
                    selectedBooking.mooringDateTime,
                    selectedBooking.departureDateTime,
                    door.id,
                    selectedSide,
                    selectedBerths[bookingId]
                )
            })
        }
    })

    const hideBookingDivs = ((bookingId) => {
        setShowDivs(prevState => ({
            ...prevState,
            [bookingId]: !prevState[bookingId]
        }));
    })

    const closeModal = () => {
        setShowGangwayReservationModal(false);
        setShowGraphModal(false);
        setModalClosed(true);
        fetchBookingResources(selectedBookingId);
        console.log("Modal is closing, setting modalClosed to true.selectedbookingid: ", selectedBookingId);
        console.log("modal closing, booking resources: ", bookingResources)
    };

    useEffect(() => {
        if (modalClosed) {
            console.log("Modal was closed, performing updates.");
            setTimeout(() => setModalClosed(false), 1000);
        }
    }, [modalClosed]);

    useEffect(() => {
        console.log('from operations.js, updated booking resources:', bookingResources);
    }, [bookingResources]);


    const handleGraphModalOpen13M = (vesselDoor, booking, doorSlopeData13M, gangwayId, gangwayName) => {
        setModalBooking(booking);
        setModalVesselDoor(vesselDoor);
        setModalSlopeData(doorSlopeData13M);
        setShowGraphModal(true);
        setModalGangwayId(gangwayId);
        setModalGangwayName(gangwayName);
        setSelectedBookingId(booking.id)
    };

    const handleGraphModalOpen7M = (vesselDoor, booking, doorSlopeData7M, gangwayId, gangwayName) => {
        setModalBooking(booking);
        setModalVesselDoor(vesselDoor);
        setModalSlopeData(doorSlopeData7M);
        setShowGraphModal(true);
        setModalGangwayId(gangwayId);
        setModalGangwayName(gangwayName);
        setSelectedBookingId(booking.id)
    };

    const handleGraphModalOpen425M = (vesselDoor, booking, doorSlopeData425M, gangwayId, gangwayName) => {
        setModalBooking(booking);
        setModalVesselDoor(vesselDoor);
        setModalSlopeData(doorSlopeData425M);
        setShowGraphModal(true);
        setModalGangwayId(gangwayId);
        setModalGangwayName(gangwayName);
        setSelectedBookingId(booking.id)
    };

    const stageRef = useRef();
    const { reportImages, setReportImages } = useReportImagesContext();

    const handleAddToReports = (bookingId) => {
        const container = document.getElementById(`capture-container2-${bookingId}`); 
    console.log("hello from handleaddtoreports")
        html2canvas(container, {
            scale: 1,
            useCORS: true,
        }).then(canvas => {
            const dataURL = canvas.toDataURL();  
            try {
                // Check if session storage is supported
                if (typeof(Storage) !== "undefined") {
                    // Function to estimate the size of the dataURL
                    const estimateSize = (data) => {
                        return (data.length * 3 / 4) - (data.split('=').length - 1);
                    };
                    
                    const dataSize = estimateSize(dataURL);
                    const storageQuota = 5 * 1024 * 1024; // Example: 5MB quota
                    const currentSize = Object.keys(sessionStorage).reduce((acc, key) => {
                        return acc + estimateSize(sessionStorage.getItem(key));
                    }, 0);
                    console.log(currentSize)
                    if (currentSize + dataSize > storageQuota) {
                        throw new Error("Session storage quota exceeded.");
                    }
    
                    // Attempt to add the dataURL to session storage
                    const testKey = "testKey";
                    sessionStorage.setItem(testKey, dataURL);
                    sessionStorage.removeItem(testKey); // Clean up after the test
                    
                    // If successful, update the report images context
                    setReportImages(prevImages => [...prevImages, dataURL]);
                } else {
                    throw new Error("Session storage is not supported.");
                }
            } catch (error) {
                console.error("Error adding report image to session storage:", error);
                toast.error("Could not add image to storage. Try deleting other images.");
            }
        }).catch(error => {
            console.error("Error capturing screenshot:", error);
            toast.error("Failed to capture screenshot. Please try again later.");
        });
    }
    

    return (
        <div >
            <ToastContainer />
            <OperationsDateTide
                getDateChange={handleDateChange}
                token={token}
                userPortId={userPortId}
            />


            {bookings.map((booking) => (
                <div className='operationsContainerWrapper' key={booking.id}>
                    <div className='operationsContainer' id={`capture-container2-${booking.id}`}  >
                        
                        <div className="operationsTopList" key={booking.id}>

                            <h3 className='operationsTopHeader'>Booking</h3>

                            <label className='operationsTopLabels'>Vessel Name:</label>
                            <p className='operationsTopText'>{booking.vesselName}</p>

                            <label className='operationsTopLabels'>Mooring Date and Time:</label>
                            <p className='operationsTopText'>{new Date(booking.mooringDateTime).toLocaleString(undefined, { dateStyle: 'long', timeStyle: 'short' })}</p>

                            <label className='operationsTopLabels'>Departure Date and Time:</label>
                            <p className='operationsTopText'>{new Date(booking.departureDateTime).toLocaleString(undefined, { dateStyle: 'long', timeStyle: 'short' })}</p>

                            <button data-html2canvas-ignore className="btn addToReports" onClick={() => handleAddToReports(booking.id)}><FaFolderPlus className="addToReportsIcon" /></button>

                            {!showDivs[booking.id] && <button data-html2canvas-ignore className="btn navBookings" onClick={() => showBookingDivs(booking.id)}>
                                <MdExpandMore className="bookingsIcon" />
                            </button>}

                            {showDivs[booking.id] && <button data-html2canvas-ignore className="btn navBookings" onClick={() => hideBookingDivs(booking.id)}>
                                <MdExpandLess className="bookingsIcon" />
                            </button>
                                //&& <button className="btn addToReports" onClick={handleAddToReports}><FaFolderPlus className="addToReportsIcon"/></button>
                            }

                        </div>

                        {showDivs[booking.id] &&
                            <div>
                                <div className="operationsListTesting" key={booking.id}>

                                    <div className="col-md-2">
                                        <label className="operationsLabels" htmlFor="selectedSide">Select Side:</label>
                                        <select
                                            className="form-control"
                                            id="selectedSide"
                                            value={selectedSide[booking.id] || booking.vesselOrientation}
                                            //disabled={hasBookingResource(booking.id)}
                                            onChange={(e) => handleSideChange(e, booking)}
                                        >
                                            <option>PORT</option>
                                            <option>STARBOARD</option>
                                        </select>
                                    </div>

                                    <div className="col-md-2">
                                        <label className="operationsLabels" htmlFor="selectedBerth">Select Berth:</label>
                                        <select
                                            className='form-control'
                                            // disabled={hasBookingResource(booking.id)}
                                            id="berthName"
                                            value={selectedBerths[booking.id] || ''}
                                            onChange={(e) => handleBerthChange(e, booking)}
                                        >
                                            {berthInformation.map((berth) => (
                                                <option key={berth.id} value={berth.id}> {berth.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className='testingTop col-md-12'>
                                    <div className='testing1 '> <label className='operationsLabels'>Vessel Door:</label></div>
                                    <div className='testing2 '> <label className='operationsLabels'>Gangway 13M:</label></div>
                                    <div className='testing2 '> <label className='operationsLabels'>Gangway 7M:</label></div>
                                    <div className='testing2 '> <label className='operationsLabels'>Gangway 4.25M:</label></div>
                                </div>

                                {vesselDoors[booking.id] && vesselDoors[booking.id].filter(vesselDoor => vesselDoor.side === selectedSide[booking.id]).map((vesselDoor, index) => {
                                    const isResourceFound13M = bookingResources.some(resource =>
                                        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 2);
                                    const foundResource13M = bookingResources.find(resource =>
                                        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 2);
                                    const isResourceFound7M = bookingResources.some(resource =>
                                        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 3);
                                    const foundResource7M = bookingResources.find(resource =>
                                        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 3);
                                    const isResourceFound425M = bookingResources.some(resource =>
                                        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 4);
                                    const foundResource425M = bookingResources.find(resource =>
                                        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 4);
                                    return (
                                        <div className='testingTop col-md-12'>
                                            <div className='testing1 '>
                                                <p className='operationsText'>{vesselDoor.name} </p></div>

                                            <div className='testing2 ' width={400} height={170}>
                                                <div className={`operationsList${(isResourceFound13M || foundResource13M) ? 'Border' : ''}`} key={index}>
                                                    <ComposedChart
                                                        width={400}
                                                        height={125}
                                                        data={doorSlopeData13M[vesselDoor.id] || []}
                                                        margin={{
                                                            top: 10, right: 30, left: 0, bottom: 0,
                                                        }}
                                                        barCategoryGap={0}
                                                        barGap={0}
                                                        onClick={() => handleGraphModalOpen13M(vesselDoor, booking, doorSlopeData13M, 2, '13M')}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} />
                                                        <YAxis domain={[-40, 40]} allowDataOverflow={true} />
                                                        <Tooltip cursor={false} content={<CustomTooltip />} />
                                                        <Bar
                                                            dataKey="slopeMultiples"
                                                            fill={(entry) => getColorBasedOnValue(entry.slope)}
                                                            label={{ position: 'top' }}
                                                            barSize={17}
                                                        >
                                                            {doorSlopeData13M[vesselDoor.id]?.map((entry, index) => (
                                                                <Cell
                                                                    key={`cell-${index}`}
                                                                    fill={getColorBasedOnValue(entry.slope)}
                                                                    stroke={getColorBasedOnValue(entry.slope)}
                                                                    strokeWidth={2}
                                                                />
                                                            ))}
                                                        </Bar>
                                                        <ReferenceLine x={booking.mooringDateTime} stroke="green" label="MT" />
                                                        <ReferenceLine x={booking.departureDateTime} stroke="red" label="DT" />
                                                        <Line
                                                            type="monotone"
                                                            dataKey="slope"
                                                            stroke="#8884d8"
                                                            dot={false}
                                                            strokeWidth={2}
                                                        />
                                                    </ComposedChart>
                                                </div>
                                            </div>
                                            <div className='testing2 '>
                                                <div className={`operationsList${(isResourceFound7M || foundResource7M) ? 'Border' : ''}`} key={index}>
                                                    <ComposedChart
                                                        width={400}
                                                        height={125}
                                                        data={doorSlopeData7M[vesselDoor.id] || []}
                                                        margin={{
                                                            top: 10, right: 30, left: 0, bottom: 0,
                                                        }}
                                                        barCategoryGap={0}
                                                        barGap={0}
                                                        onClick={() => handleGraphModalOpen7M(vesselDoor, booking, doorSlopeData7M, 3, '7M')}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} />
                                                        <YAxis domain={[-40, 40]} allowDataOverflow={true} />
                                                        <Tooltip cursor={false} content={<CustomTooltip />} />
                                                        <Bar
                                                            dataKey="slopeMultiples"
                                                            fill={(entry) => getColorBasedOnValue(entry.slope)}
                                                            label={{ position: 'top' }}

                                                        >
                                                            {doorSlopeData7M[vesselDoor.id]?.map((entry, index) => (
                                                                <Cell
                                                                    key={`cell-${index}`}
                                                                    fill={getColorBasedOnValue(entry.slope)}
                                                                    stroke={getColorBasedOnValue(entry.slope)}
                                                                    strokeWidth={2}
                                                                />
                                                            ))}
                                                        </Bar>
                                                        <ReferenceLine x={booking.mooringDateTime} stroke="green" label="MT" />
                                                        <ReferenceLine x={booking.departureDateTime} stroke="red" label="DT" />
                                                        <Line
                                                            type="monotone"
                                                            dataKey="slope"
                                                            stroke="#8884d8"
                                                            dot={false}
                                                            strokeWidth={2}
                                                        />
                                                    </ComposedChart>
                                                </div>
                                            </div>
                                            <div className='testing2 '>
                                                <div className={`operationsList${(isResourceFound425M || foundResource425M) ? 'Border' : ''}`} key={index}>
                                                    <ComposedChart
                                                        width={400}
                                                        height={125}
                                                        data={doorSlopeData425M[vesselDoor.id] || []}
                                                        margin={{
                                                            top: 10, right: 30, left: 0, bottom: 0,
                                                        }}
                                                        barCategoryGap={0}
                                                        barGap={0}
                                                        onClick={() => handleGraphModalOpen425M(vesselDoor, booking, doorSlopeData425M, 4, "4.25M")}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} />
                                                        <YAxis domain={[-40, 40]} allowDataOverflow={true} />

                                                        <Tooltip cursor={false} content={<CustomTooltip />} />
                                                        <Bar
                                                            dataKey="slopeMultiples"
                                                            fill={(entry) => getColorBasedOnValue(entry.slope)}
                                                            label={{ position: 'top' }}
                                                        >
                                                            {doorSlopeData425M[vesselDoor.id]?.map((entry, index) => (
                                                                <Cell
                                                                    key={`cell-${index}`}
                                                                    fill={getColorBasedOnValue(entry.slope)}
                                                                    stroke={getColorBasedOnValue(entry.slope)}
                                                                    strokeWidth={2}
                                                                />
                                                            ))}
                                                        </Bar>
                                                        <ReferenceLine x={booking.mooringDateTime} stroke="green" label="MT" />
                                                        <ReferenceLine x={booking.departureDateTime} stroke="red" label="DT" />
                                                        <Line
                                                            type="monotone"
                                                            dataKey="slope"
                                                            stroke="#8884d8"
                                                            dot={false}
                                                            strokeWidth={2}
                                                        />
                                                    </ComposedChart>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                )
                                }
                            </div>}
                    </div>
                </div>
            ))
            }

            {showGraphModal && (
                <OperationsGraphModal
                    closeModal={closeModal}
                    getColorBasedOnValue={getColorBasedOnValue}
                    CustomTooltip={CustomTooltip}
                    vesselDoor={modalVesselDoor}
                    booking={modalBooking}
                    doorSlopeData={modalSlopeData}
                    handleGangwayReservation={handleGangwayReservation}
                    doorGangwaySelection={doorGangwaySelection}
                    selectedBerths={selectedBerths}
                    gangwayId={modalGangwayId}
                    gangwayName={modalGangwayName}
                />
            )}
        </div >
    );
}

export default Operations;