import React, { useState, useEffect, useContext } from "react";
import {
  AreaChart, Label, ReferenceArea, ComposedChart, Bar, BarChart, Cell, LineChart,
  Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine
} from "recharts";
import 'moment-timezone';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datetime/css/react-datetime.css";
import {CustomTooltip} from "./OperationsMapCustomToolTip";
import {getColorBasedOnValue} from "./OperationsMapGetColorUtil";
import { useVesselDataContext } from "../../Contexts/useVesselDataContext";



const OperationsMapShowGraph = ({
  vesselId,
  selectedDoor,
  doorSlopeData13M,
  doorSlopeData7M,
  doorSlopeData425M,
  bookingResources,
  handleGraphModalOpen
}) => {
  console.log("Hello from OperationsMapShowGraph, door slope data 13: ", doorSlopeData13M)
  const { vesselData, setVesselData } = useVesselDataContext();

  const vessel = vesselData.find(v => v.vesselId === vesselId);

const formattedTime = (value) =>{
  const date = new Date(value); 
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}
console.log("Mooring Time:", vessel.mooringDateTime);
console.log("Departure Time:", vessel.departureDateTime);

  const isResourceFound13M = bookingResources.some(resource =>
    resource.bookingId === vessel.bookingId && resource.vesselDoorId === selectedDoor.id && resource.gangwayId === 2);
  const foundResource13M = bookingResources.find(resource =>
    resource.bookingId === vessel.bookingId && resource.vesselDoorId === selectedDoor.id && resource.gangwayId === 2);
  const isResourceFound7M = bookingResources.some(resource =>
    resource.bookingId === vessel.bookingId && resource.vesselDoorId === selectedDoor.id && resource.gangwayId === 3);
  const foundResource7M = bookingResources.find(resource =>
    resource.bookingId === vessel.bookingId && resource.vesselDoorId === selectedDoor.id && resource.gangwayId === 3);
  const isResourceFound425M = bookingResources.some(resource =>
    resource.bookingId === vessel.bookingId && resource.vesselDoorId === selectedDoor.id && resource.gangwayId === 4);
  const foundResource425M = bookingResources.find(resource =>
    resource.bookingId === vessel.bookingId && resource.vesselDoorId === selectedDoor.id && resource.gangwayId === 4);

            if (!vessel) {
              console.error(`Vessel with id ${vesselId} not found in vesselData`, vesselData);
              return null;
            }
        
            if (vessel.berthId === 4) {
              return null; 
            }

            const berthMap = {
              2: "South East",
              3: "South West",
              4: "East",
              5: "Tender"
            };
            const positionMap = {
              2: { x: 1545, y: 350 },
              3: { x: 130, y: 350 },
              4: { x: 1545, y: 100 },
            };
            const berthName = berthMap[vessel.berthId] || "Berth Unknown";
            const position = positionMap[vessel.berthId] || {x: 0, y:0};
        
            return (
              <div
                className={`operationsPictureShowGraphs ${berthName.replace(/\s+/g, '').toLowerCase()}`}
                style={{
                  left: position.x,
                  top: position.y,
                }}
              >
                <div className="chartTitle"> Berth: {[berthName]} </div>
                <div className="chartTitle"> Door Name: {selectedDoor.name}, Gangway: 13M </div>
                <div className={`operationsListModal${(isResourceFound13M || foundResource13M) ? 'Border' : ''}`}>
        
                  <ComposedChart
                    width={300}
                    height={100}
                    data={doorSlopeData13M[selectedDoor.id] || []}
                    margin={{
                      top: 10, right: 30, left: 0, bottom: 0,
                    }}
                    barCategoryGap={0}
                    barGap={0}
                    onClick={() => handleGraphModalOpen(selectedDoor.id, selectedDoor.name, vessel, doorSlopeData13M, 2, '13M')}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                      interval={15}
                      tick={{ fontSize: 12 }} 
                    />
                    <YAxis domain={[-40, 40]} allowDataOverflow={true} 
                    tick={{ fontSize: 12 }} 
                    />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <Bar
                      dataKey="slopeMultiples"
                      fill={(entry) => getColorBasedOnValue(entry.slope)}
                      label={{ position: 'top' }}
                      barSize={20}
                    >
                      {doorSlopeData13M[selectedDoor.id]?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={getColorBasedOnValue(entry.slope)}
                          stroke={getColorBasedOnValue(entry.slope)}
                          strokeWidth={2}
                        />
                      ))}
                    </Bar>
                    <ReferenceLine x={ formattedTime(vessel.mooringDateTime)} stroke="green" label="MT" />
                    <ReferenceLine x={formattedTime(vessel.departureDateTime)} stroke="red" label="DT" />
                    <Line
                      type="monotone"
                      dataKey="slope"
                      stroke="#8884d8"
                      dot={false}
                      strokeWidth={2}
                    />
                  </ComposedChart>
                </div>
                <div className="chartTitle"> Door Name: {selectedDoor.name}, Gangway name: 7M </div>
                <div className={`operationsListModal${(isResourceFound7M || foundResource7M) ? 'Border' : ''}`}>
                  <ComposedChart
                    width={300}
                    height={100}
                    data={doorSlopeData7M[selectedDoor.id] || []}
                    margin={{
                      top: 10, right: 30, left: 0, bottom: 0,
                    }}
                    barCategoryGap={0}
                    barGap={0}
                    onClick={() => handleGraphModalOpen(selectedDoor.id, selectedDoor.name, vessel, doorSlopeData7M, 3, '7M')}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                      interval={15}
                      tick={{ fontSize: 12 }} 
                    />
                    <YAxis domain={[-40, 40]} allowDataOverflow={true}
                    tick={{ fontSize: 12 }} 
                     />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <Bar
                      dataKey="slopeMultiples"
                      fill={(entry) => getColorBasedOnValue(entry.slope)}
                      label={{ position: 'top' }}
                      barSize={20}
                    >
                      {doorSlopeData7M[selectedDoor.id]?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={getColorBasedOnValue(entry.slope)}
                          stroke={getColorBasedOnValue(entry.slope)}
                          strokeWidth={2}
                        />
                      ))}
                    </Bar>
                    <ReferenceLine x={new Date(vessel.mooringDateTime).getTime()} stroke="green" label="MT" />
                    <ReferenceLine x={vessel.departureDateTime} stroke="red" label="DT" />
                    <Line
                      type="monotone"
                      dataKey="slope"
                      stroke="#8884d8"
                      dot={false}
                      strokeWidth={2}
                    />
                  </ComposedChart>
                </div>
                <div className="chartTitle"> Door Name: {selectedDoor.name}, Gangway name: 4.25M </div>
                <div className={`operationsListModal${(isResourceFound425M || foundResource425M) ? 'Border' : ''}`}>
                  <ComposedChart
                    width={300}
                    height={100}
                    data={doorSlopeData425M[selectedDoor.id] || []}
                    margin={{
                      top: 10, right: 30, left: 0, bottom: 0,
                    }}
                    barCategoryGap={0}
                    barGap={0}
                    onClick={() => handleGraphModalOpen(selectedDoor.id, selectedDoor.name, vessel, doorSlopeData425M, 4, '425M')}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                      interval={15}
                      tick={{ fontSize: 12 }} 
                    />
                    <YAxis domain={[-40, 40]} allowDataOverflow={true}
                    tick={{ fontSize: 12 }} 
                     />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <Bar
                      dataKey="slopeMultiples"
                      fill={(entry) => getColorBasedOnValue(entry.slope)}
                      label={{ position: 'top' }}
                      barSize={20}
                    >
                      {doorSlopeData425M[selectedDoor.id]?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={getColorBasedOnValue(entry.slope)}
                          stroke={getColorBasedOnValue(entry.slope)}
                          strokeWidth={2}
                        />
                      ))}
                    </Bar>
                    <ReferenceLine x={vessel.mooringDateTime} stroke="green" label="MT" />
                    <ReferenceLine x={vessel.departureDateTime} stroke="red" label="DT" />
                    <Line
                      type="monotone"
                      dataKey="slope"
                      stroke="#8884d8"
                      dot={false}
                      strokeWidth={2}
                    />
                  </ComposedChart>
                </div>
              </div>
            );
          };
    


  export default OperationsMapShowGraph;