import React, { useState } from 'react';
import SelectedDateContext from './SelectedDateContext';

const SelectedDateProvider = ({ children }) => {
    const [selectedDate, setSelectedDate] = useState([]); 

    return (
        <SelectedDateContext.Provider value={{ selectedDate, setSelectedDate }}>
            {children}
        </SelectedDateContext.Provider>
    );
};

export default SelectedDateProvider;