import 'bootstrap/dist/css/bootstrap.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import "react-datetime/css/react-datetime.css";
import 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrash, FaSave, FaCalendarCheck } from "react-icons/fa";
import { ComposedChart, Bar, BarChart, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from "recharts";
import useOperationsPictureBookingResources from './useOperationsMapBookingResources';
import { CustomTooltip } from "./OperationsMapCustomToolTip";
import { getColorBasedOnValue } from "./OperationsMapGetColorUtil";
import { FaFolderPlus } from "react-icons/fa";
import html2canvas from 'html2canvas';
import { useReportImagesContext } from '../../Contexts/useReportImagesContext';
import OperationsMapAddGangwayReservationForm from './OperationsMapAddGangwayReservationForm';

function OperationsMapGraphModal(
    {
        closeModal,
        vesselDoorId,
        vesselDoorName,
        booking,
        doorSlopeData,
        doorGangwaySelection,
        gangwayId,
        gangwayName }) {

    const modalRef = useRef(null);
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
    const {
        bookingResources,
        handleRemoveBookingResource,
        handleSelectBookingResource,
        handleUpdateBookingResource,
        fetchBookingResources,
        hasBookingResource
    } = useOperationsPictureBookingResources(token)

    const [showGangwayReservation, setShowGangwayReservation] = useState(false);
    

    const formattedTime = (value) => {
        const date = new Date(value);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      };

      console.log("Hello from operationsMapGraphModal, mooring datetime: ", booking.mooringDateTime)
    
      useEffect(() => {
        let handler = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                closeModal()
            }
        };
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [closeModal]);

    const { reportImages, setReportImages } = useReportImagesContext();
    const handleAddToReports = (bookingId) => {
        const container = document.getElementById(`capture-container4-${bookingId}`);

        html2canvas(container, {
            scale: 1,
            useCORS: true,
        }).then(canvas => {
            //console.log(canvas);
            const dataURL = canvas.toDataURL();
            setReportImages(prevImages => [...prevImages, dataURL]);
        });
    }

    const isResourceFound = bookingResources.some(resource =>
        resource.bookingId === booking.bookingId && resource.vesselDoorName === vesselDoorId && resource.gangwayId === gangwayId);
    const foundResource = bookingResources.find(resource =>
        resource.bookingId === booking.bookingId && resource.vesselDoorName === vesselDoorId && resource.gangwayId === gangwayId);

    return (
        <div className={`operationsListModal${isResourceFound ? 'Border' : ''}`}>
            {/* <ToastContainer /> */}
            <div className="pictureGraphModalContainer"  >
                <div className="operationsGraphModal" ref={modalRef} id={`capture-container4-${booking.id}`}>
                    <p>Door Name: {vesselDoorName}, Gangway: {gangwayName}</p>
                    <div className="chartContainer" >

                        <ResponsiveContainer>
                            <ComposedChart
                                width={1200}
                                height={225}
                                data={doorSlopeData[vesselDoorId] || []}
                                margin={{
                                    top: 10, right: 30, left: 0, bottom: 0,
                                }}
                                barCategoryGap={0}
                                barGap={0}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} />
                                <YAxis domain={[-40, 40]} allowDataOverflow={true} />
                                <Tooltip cursor={false} content={<CustomTooltip />} />
                                <Bar
                                    dataKey="slopeMultiples"
                                    fill={(entry) => getColorBasedOnValue(entry.slope)}
                                    label={{ position: 'top' }}
                                    barSize={37}
                                >
                                    {doorSlopeData[vesselDoorId]?.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={getColorBasedOnValue(entry.slope)}
                                            stroke={getColorBasedOnValue(entry.slope)}
                                            strokeWidth={9}
                                        />
                                    ))}
                                </Bar>
                                <ReferenceLine x={booking.mooringDateTime} stroke="green" label="Mooring Time" />
                                <ReferenceLine x={formattedTime(booking.departureDateTime)} stroke="red" label="Departure Time" />
                                <Line
                                    type="monotone"
                                    dataKey="slope"
                                    stroke="#8884d8"
                                    dot={false}
                                    strokeWidth={2}
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                        <div className="iconContainer">

                            <button data-html2canvas-ignore type="button" className="graphModalCancel" onClick={closeModal}>
                                <span>&times;</span>
                            </button>
                            <button data-html2canvas-ignore className="graphModalCancel" onClick={() => handleAddToReports(booking.id)}><FaFolderPlus className="addToReportsIcon" /></button>
                            {isResourceFound ? (
                                <>
                                    <button data-html2canvas-ignore className="btn delete" onClick={() => {
                                        handleRemoveBookingResource(booking.bookingId, vesselDoorId, foundResource?.gangwayId)
                                        setShowGangwayReservation(false)
                                    }}>
                                        <FaTrash className='deleteIcon' />
                                    </button>
                                </>
                            ) : (
                                <button data-html2canvas-ignore className="btn save"
                                    onClick={
                                        () => {
                                            // handleSelectBookingResource(booking.bookingId, vesselDoor, gangwayId, booking.berthId)
                                            setShowGangwayReservation(true)
                                        }}>
                                    <FaSave className='deleteIcon' />
                                </button>
                            )}
                        </div>
                    </div>
                    {showGangwayReservation ? (
                        <OperationsMapAddGangwayReservationForm
                            closeModal={closeModal}
                            bookingId={booking.bookingId}
                            gangwayId={gangwayId}
                            vesselDoorId={vesselDoorId}
                            mooringDateTime={booking.mooringDateTime}
                            departureDateTime={booking.departureDateTime}
                            berthName={booking.berth}
                        />) : (<div />)}
                </div>
            </div>
        </div>
    )
}

export default OperationsMapGraphModal;