import { useLocation } from 'react-router-dom';
import Header from './Header';
import Header2 from './Header2';

const ConditionalHeader = ({ authenticated, logout }) => {
  const location = useLocation();
console.log("Hello from conditional Header; ", authenticated)
  return (
    <>
      {location.pathname === '/operationsMap' ? (
        <Header2 isAuthenticated={authenticated} logout={logout} />
      ) : (
        <Header isAuthenticated={authenticated} logout={logout} />
      )}
    </>
  );
};

export default ConditionalHeader;
