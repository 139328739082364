import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserRoleContext } from '../../Contexts/useUserRoleContext';
import msalInstance from '../../Config/MsalConfig';

function Login({ setAuthenticated }) {
  const { setUserRole } = useUserRoleContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Ensure MSAL instance is initialized
  //   const initializeMsal = async () => {
  //     try {
  //       const account = msalInstance.getAllAccounts()[0];
  //       if (account) {
  //         const response = await msalInstance.acquireTokenSilent({
  //           scopes: ["api://b9d6d824-e61f-4a90-88b5-3bd8e902c76c/AccesstheApi"],
  //           account,
  //         });

  //         const { accessToken } = response;
  //         localStorage.setItem("jwtToken", accessToken);
  //         sessionStorage.setItem("jwtToken", accessToken);

  //         //setUserRole('user-role-from-backend'); // Replace with actual role API logic
  //         setAuthenticated(true);
  //         navigate('/operationsMap');
  //       }
  //     } catch (error) {
  //       console.error("Error acquiring token silently:", error);
  //     }
  //   };

  //   initializeMsal();
  // }, [navigate, setAuthenticated, setUserRole]);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const loginResponse = await msalInstance.loginPopup({
        scopes: ["api://d7c2bf62-20bf-4852-8082-a615dd4aa053/App.Read"],
      });

      const { accessToken } = loginResponse;

      localStorage.setItem("jwtToken", accessToken);
      sessionStorage.setItem("jwtToken", accessToken);
      sessionStorage.setItem("userPortId", 1);
      setUserRole('PortAdmin');

     // setUserRole('user-role-from-backend'); // Replace with actual role API logic
      setAuthenticated(true);
      navigate('/operationsMap');
    } catch (error) {
      console.error('Login failed:', error);
      toast.error('Login Failed. Please try again.', { position: "top-center" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="vh-50">
      <ToastContainer />
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <button
              onClick={handleLogin}
              className="btn btn-primary btn-lg"
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Login with Azure'}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
