export const berthByFactor = 
{
    'SOUTH EAST': [
        {xCoordinateFactor: 1.55},
        {yCoordinateFactor: 1.8}
    ],
    
    'SOUTH WEST': [
        {xCoordinateFactor: 3.3},
        {yCoordinateFactor: 1.8}
    ],
    
    'EAST': [
        {xCoordinateFactor: 1.35},
        {yCoordinateFactor: 2.2}
    ],
    
    'TENDER': [
        {xCoordinateFactor: 2.4},
        {yCoordinateFactor: 1.3}
    ]
}

// berth ids for charlottetown are 1- SOUTH EAST , 2 - SOUTH WEST , 3 - EAST , 4 - TENDER

// const southWestVesselPositionX = (imageSize.width / 3.3);
// const southWestVesselPositionY = (imageSize.height / 1.8);

// const southEastVesselPositionX = (imageSize.width / 1.55);
// const southEastVesselPositionY = (imageSize.height / 1.8);

// const eastVesselPositionX = (imageSize.width / 1.35);
// const eastVesselPositionY = (imageSize.height / 2.2);

// const tenderVesselPositionX = (imageSize.width / 2.4);
// const tenderVesselPositionY = (imageSize.height / 1.30);


