import React from 'react';
import Constants from '../../Constants';
import { FaEdit, FaTrash } from "react-icons/fa";
import {Link} from "react-router-dom";

const formattedDateTime = (dateTimeString) => {
    return new Date(dateTimeString).toLocaleString(undefined, { dateStyle: 'long', timeStyle: 'short' });
  };

export const Columns = ({handleSelect,handleDelete }) => [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ cell: { row } }) => (
        <a href={Constants.FRONT_END + `VesselDetails/${row.original.id}`}>
          {row.original.name}
        </a>
      ),
  },
  {
    Header: 'Crew',
    accessor: 'crew',
  },
  {
    Header: 'Length',
    accessor: 'length',
    
  },
  {
    Header: '',
    accessor: 'actions',
    Cell: ({ row }) => (
        
      <div className="cell-actions" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {/*<button className="btn edit" onClick={() => handleSelect(row.original)}>*/}
      {/*  <FaEdit className='editIcon'/>*/}
      {/*</button>*/}

        <Link
            to={{
              pathname: `/VesselDetails/${row.original.id}`
            }}
        >
          <button className="btn edit"><FaEdit className='editIcon'/></button>
        </Link>

      <button className="btn delete" onClick={() => handleDelete(row.original)}>
        <FaTrash className='deleteIcon'/>
      </button>
    </div>
      ),
  },
];


