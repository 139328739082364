import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import { useReportImagesContext } from '../../Contexts/useReportImagesContext';


const handleAddToReports = (setReportImages) => {

    const container = document.getElementById(`capture-container`);
    
    html2canvas(container, {
      scale: 1,
      useCORS: true,
    }).then(canvas => {
      const dataURL = canvas.toDataURL();
      try {
        // Check if session storage is supported
        if (typeof (Storage) !== "undefined") {
          // Function to estimate the size of the dataURL
          const estimateSize = (data) => {
            return (data.length * 3 / 4) - (data.split('=').length - 1);
          };
          const dataSize = estimateSize(dataURL);
          const storageQuota = 5 * 1024 * 1024; // Example: 5MB quota
          const currentSize = Object.keys(sessionStorage).reduce((acc, key) => {
            return acc + estimateSize(sessionStorage.getItem(key));
          }, 0);
          if (currentSize + dataSize > storageQuota) {
            throw new Error("Session storage quota exceeded.");
          }
          // Attempt to add the dataURL to session storage
          const testKey = "testKey";
          sessionStorage.setItem(testKey, dataURL);
          sessionStorage.removeItem(testKey); // Clean up after the test
          // If successful, update the report images context
          setReportImages(prevImages => [...prevImages, dataURL]);
        } else {
          throw new Error("Session storage is not supported.");
        }
      } catch (error) {
        console.error("Error adding report image to session storage:", error);
        toast.error("Could not add image to storage. Try deleting other images.");
      }
    }).catch(error => {
      console.error("Error capturing screenshot:", error);
      toast.error("Failed to capture screenshot. Please try again later.");
    });
  };

  export default handleAddToReports;