import { useContext } from 'react';
import BookingResourcesContext from './BookingResourcesContext';

export const useBookingResourcesContext = () => {
    const context = useContext(BookingResourcesContext);
    if (!context) {
        throw new Error('useBookingResources must be used within a BookingResourcesProvider');
    }
    return context;
};
