import React, { createContext, useState, useContext } from 'react';

const GangwayReservationsContext = createContext();

const GangwayReservationsProvider = ({ children }) => {
    const [gangwayReservations, setGangwayReservations] = useState([]); 

    return (
        <GangwayReservationsContext.Provider value={{ gangwayReservations, setGangwayReservations }}>
            {children}
        </GangwayReservationsContext.Provider>
    );
};

const useGangwayReservationsContext = () => {
    const context = useContext(GangwayReservationsContext);
    if (!context) {
        throw new Error('useGangwayReservationsContext must be used within a GangwayReservationsProvider');
    }
    return context;
};

export { GangwayReservationsProvider, useGangwayReservationsContext };
