import React, { createContext, useState, useContext } from 'react';

const RenderFlagContext = createContext();

export const RenderFlagProvider = ({ children }) => {
  const [renderVersion, setRenderVersion] = useState(0);

  const incrementRenderVersion = () => {
    setRenderVersion(prevVersion => prevVersion + 1);
  };

  return (
    <RenderFlagContext.Provider value={{ renderVersion, incrementRenderVersion }}>
      {children}
    </RenderFlagContext.Provider>
  );
};

export const useRenderFlag = () =>{
  const context = useContext(RenderFlagContext);
  if(!context){
    throw new Error("useRenderFlag must be used within a RenderFlagProvider")
  };
  return context;
}