import React, { useState, useEffect, useContext } from 'react';
import DateTime from 'react-datetime';
import moment from 'moment';
import 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserRoleContext from '../../Contexts/UserRoleContext';
import 'bootstrap/dist/css/bootstrap.css';
import { FaCalendarPlus, FaCalendarTimes  } from "react-icons/fa";
import useOperationsMapBookingResources from './useOperationsMapBookingResources';

const OperationsMapAddGangwayReservationForm = ({ closeModal, bookingId, gangwayId, vesselDoorId, mooringDateTime, departureDateTime, berthName }) => {

    const [formData, setFormData] = useState({
        bookingId: '',
        gangwayId: '',
        vesselDoorId: '',
        startDateTime: '',
        endDateTime: '',
        resourceType: 'Gangway'
    });

    const [errors, setErrors] = useState({});
    const [reservationDateError, setReservationDateError] = useState(false);
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const { userRole } = useContext(UserRoleContext);

    const {
        addGangwayReservation,
        fetchBookingResources
      } = useOperationsMapBookingResources(token)

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            bookingId: bookingId,
            gangwayId: gangwayId,
            vesselDoorId:vesselDoorId,
            startDateTime: mooringDateTime,
            endDateTime: departureDateTime
        }));
    }, [bookingId, gangwayId]);
 
     const handleDateChange = (date, id) => {
        const currentFormDate = formData[id] ? moment(formData[id]) : moment();
        
        const newTime = date ? moment(date).format('HH:mm:ss') : '';
        const updatedDate = currentFormDate.set({
            hour: moment(date).get('hour'),
            minute: moment(date).get('minute'),
            second: moment(date).get('second')
        }).format('YYYY-MM-DDTHH:mm:ss');
    
        setFormData(prevFormData => {
            const newFormData = { ...prevFormData, [id]: updatedDate };
    
            if (newFormData.startDateTime && newFormData.endDateTime) {
                setReservationDateError(moment(newFormData.startDateTime).isSameOrAfter(moment(newFormData.endDateTime)));
            }
            return newFormData;
        });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!["PortAdmin"].includes(userRole)) {
            toast.error('You do not have the necessary permissions to add this reservation.', {
                position: "top-center",
                hideProgressBar: true
            });
            return;
        }
        if (validateForm()) {
            try {
                await addGangwayReservation(formData, closeModal);
                fetchBookingResources(formData.bookingId);
            } catch (error) {
                console.error("Error updating gangway reservation:", error);
            }
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.startDateTime) { newErrors.startDateTime = 'Start Time is required'; }
        if (formData.startDateTime && formData.endDateTime && moment(formData.startDateTime).isSameOrAfter(moment(formData.endDateTime))) {newErrors.startDateTime = 'Start Time is after end time'; }
        if (!formData.endDateTime) { newErrors.endDateTime = 'End Time is required'; }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const isValidTimeRange = (current) => {
        return current.isSameOrAfter(mooringDateTime) && current.isBefore(departureDateTime)
     }
     const isValidStartTime = (current) => {
        return current.isSameOrAfter(mooringDateTime)
     }

    return (
        <div>
            {/* <ToastContainer /> */}
            <form id="bookingsUpdateModal1" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-3 reserveHeader"> <h5>Do you want to reserve this gangway?</h5>
                        </div>
                        <div className="col-md-3">
                            <label className="modalLables" htmlFor="startDateTime">Start Time <span className="required">*</span></label>
                            <DateTime
                                dateFormat={false}
                                isValidDate={isValidStartTime}
                                className={`form-control ${errors.startDateTime ? 'is-invalid' : ''}`}
                                value={formData.startDateTime ? moment(formData.startDateTime) : ''}
                                onChange={date => handleDateChange(date, "startDateTime")}
                            />
                            
                        </div>
                        <div className="col-md-3">
                            <label className="modalLables" htmlFor="endTime">End Time <span className="required">*</span></label>
                            <DateTime
                                dateFormat={false}
                                className={`form-control ${errors.endDateTime ? 'is-invalid' : ''}`}
                                value={formData.endDateTime ? moment(formData.endDateTime) : ''}
                                onChange={date => handleDateChange(date, "endDateTime")}
                            />
                            {errors.endDateTime && <div className="invalid-feedback">{errors.endDateTime}</div>}
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                            <button type="submit" className="btn gangReserve"><FaCalendarPlus /></button>
                            <button type="button" className="btn gangNoReserve" onClick={closeModal}><FaCalendarTimes /></button>
                            {errors.startDateTime && <div className='alert alert-danger' >{errors.startDateTime}</div>}
                        </div>
                    </div>
                </form>
        </div>

    )
}
export default OperationsMapAddGangwayReservationForm;