import {Group, Image} from 'react-konva'
import React from 'react';
import useImage from 'use-image';
import Constants from '../../Constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import { vesselFactors, vesselDoors, vesselPowerDoors } from "./OperationsMapVesselInformation";
import handleError from "./OperationsMapHandleError";

const VesselImage = React.memo(({ url, x, y, scaleX, scaleY, rotation }) => {
    const [image] = useImage(url);
    return (
      <Group x={x} y={y} scaleX={scaleX} scaleY={scaleY} rotation={rotation}>
        <Image image={image} />
      </Group>
    );
  });

const rotateVessel = (vesselId, currentOrientation, setVesselData, userRole, setVesselImageOrientation) => {
  const newOrientation = currentOrientation === "PORT" ? "STARBOARD" : "PORT";

  const bookingObject = { vesselId, vesselOrientation: newOrientation }

  fetchVesselImages(bookingObject, userRole, setVesselImageOrientation)
    .then(updatedVessel => {
      if (updatedVessel) {
        setVesselData(prevVesselData => prevVesselData.map(vessel =>
          vessel.vesselId === vesselId ? { ...vessel, orientation: newOrientation, ...updatedVessel } : vessel
        ));
      } else {
        console.error("No updated vessel data received.");
      }
    })
    .catch(error => {
      console.error("Error during vessel rotation: ", error);
      toast.error("There was an error fetching the vessel image.");
    });
};

  const fetchVesselImages = (booking, userRole, setVesselImageOrientation) => {
    
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
    const { vesselId, vesselOrientation, berth } = booking;
    const apiUrlVesselImages = `${Constants.BACK_END}/api/VesselImage/vesselId/${vesselId}/vesselOrientation/${vesselOrientation}`;
    return axios.get(userRole === "User" ? "" : apiUrlVesselImages, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setVesselImageOrientation({
          vesselId,
          vesselOrientation,
        });
        const imageURL = response.data[0].imagePath;
        const factor = vesselFactors[vesselId];
        const doors = vesselDoors[vesselId];
        const powerDoors = vesselPowerDoors[vesselId]
        return imageURL ? {
          ...booking,
          url: imageURL,
          factor,
          doors,
          powerDoors,
          orientation: vesselOrientation
        } : null;
        
      })
      .catch(error => {
        handleError(error, 'Getting Images');
        toast.error("There was an error fetching the vessel images.")
      });
  }

  export {VesselImage, rotateVessel, fetchVesselImages};