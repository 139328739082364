import { toast } from 'react-toastify';

const handleError = (error, context) => {
    const status = error.response?.status;
    if (status === 401) {
      toast.error('Your session has expired. Please log in again.', {
        position: "top-center",
        hideProgressBar: true
      });
    } else {
      console.error(`Error in ${context}:`, error);
      const errorMessage = error.response?.data?.message || 'An error has occurred';
      toast.error(`Error in ${context}: ${errorMessage}`);
    }
  };

  export default handleError;