import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Constants from '../../Constants';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';


const OperationsGangwayBerth = (userPortId, token) =>{

    const [gangwayInformation, setGangwayInformation] = useState([]);
    const [berthInformation, setBerthInformation] = useState([]);

    useEffect(() => {
        axios.get(`${Constants.BACK_END}/api/Gangway/portId/${userPortId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setGangwayInformation(response.data);
            })
            .catch(error => {
                console.error('Error fetching gangways:', error);
            });

        axios.get(`${Constants.BACK_END}/api/Berth/portId/${userPortId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setBerthInformation(response.data);
            })
            .catch(error => {
                console.error('Error fetching berth names:', error);
            });

    }, [userPortId, token]);

    return{gangwayInformation, berthInformation}
}
    export default OperationsGangwayBerth;
