import React, {useEffect, useState} from "react";
import axios from 'axios';
import Constants from '../../Constants';
import { toast } from 'react-toastify';
import { useBookingResourcesContext } from "../../Contexts/useBookingResourcesContext";

const OperationsBookingResources = (token) =>{

    const { bookingResources, setBookingResources } = useBookingResourcesContext();

    useEffect (()=>{
        console.log("from OBR, booking resources: ", bookingResources)
    } , [bookingResources])

    const handleSelectBookingResource = (BookingId, VesselDoorId, GangwayId, berthId) => {
        const selectedResource = {
            bookingId: BookingId,
            vesselDoorId: VesselDoorId,
            gangwayId: GangwayId
        }
        if (!bookingResources.some(resource =>
            resource.bookingId === BookingId &&
            resource.vesselDoorId === VesselDoorId &&
            resource.gangwayId === GangwayId
        )){
        axios.put(Constants.BACK_END + `/api/Booking/updateBerth/${BookingId}/${berthId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                toast.success('Booking berth updated successfully', {
                    position: "top-center",
                    hideProgressBar: true
                });
                axios.post(`${Constants.BACK_END}/api/BookingResource`, selectedResource, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    toast.success('Settings saved', {
                        position: "top-center",
                        hideProgressBar: false
                    });
                    setBookingResources(prevResources => [...prevResources, selectedResource]);
                    
                })
                .catch(error => {
                    console.error('Error fetching tide information:', error);
                });
            })
            .catch(error => {
                const errorMessage = error.response.data;
                toast.error(`Error updating booking: ${errorMessage}`, {
                    position: "top-center",
                    hideProgressBar: true
                });
                console.error('Error updating booking:', error);
            })
    }}

    const handleRemoveBookingResource = (BookingId, VesselDoorId, GangwayId) => {
        axios.delete(`${Constants.BACK_END}/api/BookingResource/delete/bookingId/${BookingId}/vesselDoorId/${VesselDoorId}/gangwayId/${GangwayId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                toast.success('Resource removed', {
                    position: "top-center",
                    hideProgressBar: false
                });
                setBookingResources(prevResources => prevResources.filter(resource => !(resource.bookingId === BookingId && resource.vesselDoorId === VesselDoorId && resource.gangwayId === GangwayId)))
            })
            .catch(error => {
                console.error('Error deleting resource:', error);
            });
    }

    const handleUpdateBookingResource = (BookingId, VesselDoorId, GangwayId) => {
        axios.put(`${Constants.BACK_END}/api/BookingResource/update/bookingId/${BookingId}/vesselDoorId/${VesselDoorId}/gangwayId/${GangwayId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                toast.success('Resource updated', {
                    position: "top-center",
                    hideProgressBar: false
                });
            })
            .catch(error => {
                console.error('Error deleting resource:', error);
            });
    }

    const fetchBookingResources = async (bookingId) => {
        console.log("hello from fetchbookingresourses.")
        try {
            // Fetch booking resources from the backend
            const response = await axios.get(`${Constants.BACK_END}/api/BookingResource/bookingId/${bookingId}`, { headers: { Authorization: `Bearer ${token}` } });
            const fetchedResources = response.data;
    
            // Update the booking resources state
            setBookingResources(prevResources => {
                // Make a copy of the current resources
                const updatedResources = [...prevResources];
    
                // Iterate over the fetched resources
                fetchedResources.forEach(resource => {
                    // Check if the resource already exists in the updated resources
                    if (!updatedResources.some(r =>
                        r.bookingId === resource.bookingId &&
                        r.vesselDoorId === resource.vesselDoorId &&
                        r.gangwayId === resource.gangwayId
                    )) {
                        // If not, add the new resource to the updated resources
                        updatedResources.push(resource);
                    }
                });
    
                // Return the updated list of resources
                return updatedResources;
            });
        } catch (error) {
            console.error('Error fetching booking resources:', error);
        }
    };
    

    const hasBookingResource = (bookingId) => {
        return bookingResources.some(resource => resource.bookingId === bookingId);
    }



    return {
        bookingResources,
        handleRemoveBookingResource,
        handleSelectBookingResource,
        handleUpdateBookingResource,
        fetchBookingResources,
        hasBookingResource
    };

}

export default OperationsBookingResources;