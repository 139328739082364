import { useEffect } from 'react';
import msalInstance from '../Config/MsalConfig';

const useRefreshToken = () => {
    useEffect(() => {
        const account = msalInstance.getAllAccounts()[0];
        
        if (!account) {
            console.log("No account found. User may not be logged in.");
            return;
        }

        // Attempt to acquire a new token silently if account exists
        msalInstance.acquireTokenSilent({
            scopes: ["api://d7c2bf62-20bf-4852-8082-a615dd4aa053/App.Read"],
            account: account,
        }).then(response => {
            const { accessToken, refreshToken } = response;

            // Store the new tokens
            localStorage.setItem("jwtToken", accessToken);
            sessionStorage.setItem("jwtToken", accessToken);

            // Optionally, if you have a refresh token, store it as well
            sessionStorage.setItem("jwtRefreshToken", refreshToken || "");

            console.log("Token refreshed successfully",accessToken );
        }).catch(error => {
            console.error('Error acquiring token silently:', error);
        });
    }, []); 
};

export default useRefreshToken;
