export const vesselFactors = {
  // factors for full hd are 0.501 and 0.775
  // factors for original were 1.34 and 2.06
  // factors for master are 2.67 and 4.12
  // factors for qhd are 0.667 and 1.033
    1: 0.775, 
    2: 0.501, 
    693: 0.775,

    734: 0.366, //seabourn ovation
    754: 0.366, //volendam
    758: 0.366, //zuiderdam

    664: 0.504, 665: 1.34, 666: 1.34, 667: 2.06, 668: 2.06, 669: 1.34,
    670: 2.06, 673: 1.34, 674: 1.34, 675: 1.34, 676: 1.34, 677: 2.06, 678: 2.06, 679: 2.06,
    680: 1.34, 681: 1.34, 682: 1.34, 683: 1.34,
    699: 2.06,
    708: 1.34,
    714: 2.06, 717: 2.16, 718: 2.06,
    723: 1.34, 725: 1.34,
    737: 2.06,
    753: 2.16,  
  };

  export const vesselDoors = {
    1: [
      //adjusted when boat factor was set to 1     /////    comments at end of line reflect door in database
      { id: 738, name: "9", orientation: "PORT", x: 290, y: 23, width: 45, height: 20 }, // 9 port 3.05
      { id: 737, name: "7", orientation: "PORT", x: 395, y: 23, width: 45, height: 20 }, // 7 port 3.05
      { id: 736, name: "1", orientation: "PORT", x: 660, y: 23, width: 45, height: 20 }, //1 port 3.05
      { id: 742, name: "9", orientation: "PORT", x: 290, y: 88, width: 45, height: 20 }, //9 star 3.05
      { id: 741, name: "7", orientation: "PORT", x: 395, y: 88, width: 45, height: 20 }, //7 star 3.05 
      { id: 740, name: "DECK 2", orientation: "PORT", x: 570, y: 88, width: 85, height: 20 }, //Deck2 star 6.05
      { id: 739, name: "1", orientation: "PORT", x: 660, y: 88, width: 45, height: 20 }, // 1 start 3.05

      { id: 738, name: "9", orientation: "STARBOARD", x: 605, y: 88, width: 45, height: 20 },// 9 port 3.05 
      { id: 737, name: "7", orientation: "STARBOARD", x: 500, y: 88, width: 45, height: 20 }, // 7 port 3.05
      { id: 736, name: "1", orientation: "STARBOARD", x: 230, y: 88, width: 45, height: 20 }, //1 port 3.05
      { id: 742, name: "9", orientation: "STARBOARD", x: 605, y: 23, width: 45, height: 20 }, //9 star 3.05
      { id: 741, name: "7", orientation: "STARBOARD", x: 500, y: 23, width: 45, height: 20 }, //7 star 3.05
      { id: 740, name: "Deck 2", orientation: "STARBOARD", x: 330, y: 23, width: 85, height: 20 }, //Deck2 star 6.05
      { id: 736, name: "1", orientation: "STARBOARD", x: 230, y: 23, width: 45, height: 20 }, // 1 start 3.05
    ],
    2: [
      //adjusted when boat factor was set to 1
      { id: 3035, name: "Gangway1", orientation: "PORT", x: 200, y: 30, width: 150, height: 20 }, //gangway1 port 10.1
      { id: 3036, name: "Ac.Lad", orientation: "PORT", x: 450, y: 30, width: 210, height: 20 }, //accom ladder port 7.1
      { id: 3037, name: "Gangway2", orientation: "PORT", x: 400, y: 55, width: 150, height: 20 }, //gangway2 port 3.9
      { id: 3038, name: "Gangway1", orientation: "PORT", x: 200, y: 80, width: 150, height: 20 }, //gangway1 star 10.1
      { id: 3039, name: "Ac.Lad", orientation: "PORT", x: 450, y: 80, width: 210, height: 20 }, //accom ladder star 7.1
      // { id: 3040, orientation: "PORT", x: 520, y: 110, width: 120, height: 20 }, // gangway2 star 3.9

      { id: 3035, name: "Gangway1", orientation: "STARBOARD", x: 655, y: 82, width: 150, height: 20 }, //gangway1 port 10.1
      { id: 3036, name: "Ac.Lad", orientation: "STARBOARD", x: 410, y: 82, width: 210, height: 20 }, //accom ladder port 7.1
      { id: 3037, name: "Gangway2", orientation: "STARBOARD", x: 460, y: 57, width: 150, height: 20 }, //gangway2 port 3.9
      { id: 3038, name: "Gangway1", orientation: "STARBOARD", x: 655, y: 32, width: 150, height: 20 }, //gangway1 star 10.1
      { id: 3039, name: "Ac.Lad", orientation: "STARBOARD", x: 410, y: 32, width: 210, height: 20 }, //accom ladder star 7.1
      // { id: 3040, orientation: "STARBOARD", x: 0, y: 150, width: 120, height: 20 }, // gangway2 star 3.9
    ],
    693: [
      //adjusted when boat factor was set to 1
      { id: 3262, name: "#9", orientation: "PORT", x: 205, y: 16, width: 45, height: 20 }, //#9 port 5
      { id: 3263, name: "#12-1", orientation: "PORT", x: 84, y: 16, width: 45, height: 20 }, //#12-1 port 5
      { id: 3264, name: "#12-2", orientation: "PORT", x: 126, y: 16, width: 45, height: 20 }, //#12-2 port 8.15
      { id: 3265, name: "#9", orientation: "PORT", x: 205, y: 48, width: 45, height: 20 }, //#9 star 5
      { id: 3266, name: "#12-1", orientation: "PORT", x: 84, y: 48, width: 45, height: 20 }, //#12-1 star 5 
      { id: 3267, name: "#12-2", orientation: "PORT", x: 126, y: 48, width: 45, height: 20 }, //#12-2  star 8.15

      { id: 3262, name: "#9", orientation: "STARBOARD", x: 205, y: 48, width: 45, height: 20  }, //#9 port 5
      { id: 3263, name: "#12-1", orientation: "STARBOARD", x: 290, y: 48, width: 45, height: 20 }, //#12-1 port 5
      { id: 3264, name: "#12-2", orientation: "STARBOARD", x: 330, y: 48, width: 45, height: 20 }, //#12-2 port 8.15
      { id: 3265, name: "#9", orientation: "STARBOARD", x: 205, y: 16, width: 45, height: 20 }, //#9 star 5
      { id: 3266, name: "#12-1", orientation: "STARBOARD", x: 290, y: 16, width: 45, height: 20 }, //#12-1 star 5
      { id: 3267, name: "#12-2", orientation: "STARBOARD", x: 330, y: 16, width: 45, height: 20 }, //#12-2 star 8.15
    ],

    //ZUIDERDAM
    758: [
      //adjusted when boat factor was set to 1
      { id: 3767, name: "#9", orientation: "PORT", x: 1300, y: 66, width: 120, height: 40 }, //#9 port 2.8
      { id: 3768, name: "#11", orientation: "PORT", x: 1135, y: 66, width: 120, height: 40 }, //#11 port 2.8
      { id: 3769, name: "#13", orientation: "PORT", x: 852, y: 66, width: 120, height: 40 }, //#13 port 2.8
      { id: 3770, name: "#25", orientation: "PORT", x: 852, y: 33, width: 120, height: 40 }, //#25 port 8.4
      { id: 3771, name: "#21", orientation: "PORT", x: 670, y: 66, width: 120, height: 40 }, //#21 port 5.7 
      { id: 3772, name: "#9", orientation: "PORT", x: 1300, y: 141, width: 120, height: 40 }, //#9 star 2.8
      { id: 3773, name: "#11", orientation: "PORT", x: 1135, y: 141, width: 120, height: 40 }, //#11 star 2.8
      { id: 3774, name: "#13", orientation: "PORT", x: 852, y: 141, width: 120, height: 40 }, //#13 star 2.8
      { id: 3775, name: "#25", orientation: "PORT", x: 852, y: 175, width: 120, height: 40 }, //#25 star 8.4
      { id: 3776, name: "#21", orientation: "PORT", x: 670, y: 141, width: 120, height: 40 }, //#21 star 5.7 
      
      { id: 3767, name: "#9", orientation: "STARBOARD", x: 445, y: 66, width: 120, height: 40 }, //#9 port 2.8
      { id: 3768, name: "#11", orientation: "STARBOARD", x: 605, y: 66, width: 120, height: 40 }, //#11 port 2.8
      { id: 3769, name: "#13", orientation: "STARBOARD", x: 888, y: 66, width: 120, height: 40 }, //#13 port 2.8
      { id: 3770, name: "#25", orientation: "STARBOARD", x: 888, y: 33, width: 120, height: 40 }, //#25 port 8.4
      { id: 3771, name: "#21", orientation: "STARBOARD", x: 1070, y: 66, width: 120, height: 40 }, //#21 port 5.7 
      { id: 3772, name: "#9", orientation: "STARBOARD", x: 445, y: 141, width: 120, height: 40 }, //#9 star 2.8
      { id: 3773, name: "#11", orientation: "STARBOARD", x: 605, y: 141, width: 120, height: 40 }, //#11 star 2.8
      { id: 3774, name: "#13", orientation: "STARBOARD", x: 888, y: 141, width: 120, height: 40 }, //#13 star 2.8
      { id: 3775, name: "#25", orientation: "STARBOARD", x: 888, y: 175, width: 120, height: 40 }, //#25 star 8.4
      { id: 3776, name: "#21", orientation: "STARBOARD", x: 1070, y: 141, width: 120, height: 40 }, //#21 star 5.7 

     // { id: 3777, name: "18SC", orientation: "PORT", x: 256, y: 65, width: 220, height: 40 }, //#18SC  port 3.4
     // { id: 3777, name: "18SC", orientation: "STARBOARD", x: 1480, y: 141, width: 220, height: 40 }, //#18SC  port 3.4
    ],

    //VOLENDAM
    754: [
      //adjusted when boat factor was set to 1
      { id: 3734, name: "#3", orientation: "PORT", x: 864, y: 68, width: 110, height: 40 }, //#3 port 3.4
      { id: 3735, name: "#5", orientation: "PORT", x: 810, y: 33, width: 120, height: 40 }, //#5 port 3.4
      { id: 3736, name: "#14", orientation: "PORT", x: 250, y: 53, width: 120, height: 40 }, //#14 port 3.4
      { id: 3737, name: "#19", orientation: "PORT", x: 1015, y: 53, width: 120, height: 40 }, //#19 port 9
      { id: 3738, name: "#4", orientation: "PORT", x: 864, y: 141, width: 120, height: 40 }, //#4 star 3.4
      { id: 3739, name: "#6", orientation: "PORT", x: 810, y: 175, width: 120, height: 40 }, //#6 star 3.4
      { id: 3740, name: "#15", orientation: "PORT", x: 250, y: 153, width: 120, height: 40 }, //#15 star 3.4
      { id: 3741, name: "#18", orientation: "PORT", x: 625, y: 153, width: 120, height: 40 }, //#18 star 6.2
      { id: 3742, name: "#20", orientation: "PORT", x: 1015, y: 155, width: 120, height: 40 }, //#20 star 9
      
      { id: 3734, name: "#3", orientation: "STARBOARD", x: 592, y: 141, width: 110, height: 40 }, //#3 port 3.4
      { id: 3735, name: "#5", orientation: "STARBOARD", x: 640, y: 175, width: 120, height: 40 }, //#5 port 3.4
      { id: 3736, name: "#14", orientation: "STARBOARD", x: 1210, y: 153, width: 120, height: 40 }, //#14 port 3.4
      { id: 3737, name: "#19", orientation: "STARBOARD", x: 440, y: 153, width: 120, height: 40 }, //#19 port 9
      { id: 3738, name: "#4", orientation: "STARBOARD", x: 592, y: 66, width: 120, height: 40 }, //#4 star 3.4
      { id: 3739, name: "#6", orientation: "STARBOARD", x: 640, y: 33, width: 120, height: 40 }, //#6 star 3.4
      { id: 3740, name: "#15", orientation: "STARBOARD", x: 1210, y: 53, width: 120, height: 40 }, //#15 star 3.4
      { id: 3741, name: "#18", orientation: "STARBOARD", x: 830, y: 53, width: 120, height: 40 }, //#18 star 6.2
      { id: 3742, name: "#20", orientation: "STARBOARD", x: 440, y: 53, width: 120, height: 40 }, //#20 star 9

     // { id: 3778, name: "SC", orientation: "PORT", x: 510, y: 53, width: 220, height: 40 }, //SC  port 3.4
     // { id: 3778, name: "SC", orientation: "STARBOARD", x: 940, y: 153, width: 220, height: 40 }, //SC  port 3.4
    ],

    //SEABOURN OVATION
    734: [
      //adjusted when boat factor was set to 1
      { id: 3586, name: "D", orientation: "PORT", x: 250, y: 53, width: 110, height: 40 }, //D port 2.1
      { id: 3587, name: "M", orientation: "PORT", x: 440, y: 53, width: 120, height: 40 }, //M port 7.9
      { id: 3588, name: "H", orientation: "PORT", x: 680, y: 53, width: 120, height: 40 }, //H port 7.9
      { id: 3589, name: "D", orientation: "PORT", x: 250, y: 143, width: 120, height: 40 }, //D star 2.1
      { id: 3590, name: "M", orientation: "PORT", x: 440, y: 131, width: 120, height: 40 }, //M star 7.9
      { id: 3591, name: "H", orientation: "PORT", x: 680, y: 131, width: 120, height: 40 }, //H star 7.9
      { id: 3592, name: "K", orientation: "PORT", x: 980, y: 131, width: 120, height: 40 }, //K star 5

      { id: 3586, name: "D", orientation: "STARBOARD", x: 1040, y: 131, width: 110, height: 40 }, //D port 2.1
      { id: 3587, name: "M", orientation: "STARBOARD", x: 850, y: 131, width: 120, height: 40 }, //M port 7.9
      { id: 3588, name: "H", orientation: "STARBOARD", x: 610, y: 131, width: 120, height: 40 }, //H port 7.9
      { id: 3589, name: "D", orientation: "STARBOARD", x: 1040, y: 43, width: 120, height: 40 }, //D star 2.1
      { id: 3590, name: "M", orientation: "STARBOARD", x: 850, y: 53, width: 120, height: 40 }, //M star 7.9
      { id: 3591, name: "H", orientation: "STARBOARD", x: 610, y: 53, width: 120, height: 40 }, //H star 7.9
      { id: 3592, name: "K", orientation: "STARBOARD", x: 305, y: 53, width: 120, height: 40 }, //K star 5


      //{ id: 3779, name: "SC", orientation: "PORT", x: 210, y: 107, width: 220, height: 40 }, //SC  port 3.4
     // { id: 3779, name: "SC", orientation: "STARBOARD", x: 1080, y: 75, width: 220, height: 40 }, //SC  port 3.4
    ]
};

export const vesselPowerDoors = {
  //ZUIDERDAM
  758: [
    //adjusted when boat factor was set to 1
    { id: 3777, name: "18SC", orientation: "PORT", x: 256, y: 65, width: 220, height: 40 }, //#18SC  port 3.4
    { id: 3777, name: "18SC", orientation: "STARBOARD", x: 1480, y: 141, width: 220, height: 40 }, //#18SC  port 3.4
  ],

  //VOLENDAM
  754: [
    //adjusted when boat factor was set to 1
    { id: 3778, name: "SC", orientation: "PORT", x: 510, y: 53, width: 220, height: 40 }, //SC  port 3.4
    { id: 3778, name: "SC", orientation: "STARBOARD", x: 940, y: 153, width: 220, height: 40 }, //SC  port 3.4
  ],

  //SEABOURN OVATION
  734: [
    //adjusted when boat factor was set to 1
    { id: 3779, name: "SC", orientation: "PORT", x: 210, y: 107, width: 220, height: 40 }, //SC  port 3.4
    { id: 3779, name: "SC", orientation: "STARBOARD", x: 1080, y: 75, width: 220, height: 40 }, //SC  port 3.4
  ]
}
