import 'bootstrap/dist/css/bootstrap.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import "react-datetime/css/react-datetime.css";
import 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrash, FaSave, FaCalendarCheck } from "react-icons/fa";
import { ComposedChart, Bar, BarChart, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from "recharts";
import useOperationsPictureBookingResources from './useOperationsMapBookingResources';
import {CustomTooltipPower} from "./OperationsMapCustomToolTip";
import { getColorBasedOnValue } from "./OperationsMapGetColorUtil";
import { FaFolderPlus } from "react-icons/fa";
import html2canvas from 'html2canvas';
import { useReportImagesContext } from '../../Contexts/useReportImagesContext';
import OperationsMapAddGangwayReservationForm from './OperationsMapAddGangwayReservationForm';

function OperationsMapPowerGraphModal(
    {
        closeModal,
        vesselDoorId,
        vesselDoorName,
        booking,
        powerDoorData 
    }) {

    const modalRef = useRef(null);
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
    console.log("operationsMapPowerGraphModal vesseldoorName", vesselDoorName)
    useEffect(() => {
        let handler = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                closeModal()
            }
        };
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [closeModal]);

    const { reportImages, setReportImages } = useReportImagesContext();
    const handleAddToReports = (bookingId) => {
        const container = document.getElementById(`capture-container4-${bookingId}`);

        html2canvas(container, {
            scale: 1,
            useCORS: true,
        }).then(canvas => {
            console.log(canvas);
            const dataURL = canvas.toDataURL();
            setReportImages(prevImages => [...prevImages, dataURL]);
        });
    }

    return (
        <div >
            {/* <ToastContainer /> */}
            <div className="pictureGraphModalContainer"  >
                <div className="operationsGraphModal" ref={modalRef} id={`capture-container4-${booking.id}`}>
                    <p>Shore Connection Door ({[vesselDoorName]}) Meters From Dock</p>
                    <div className="chartContainer" >

                        <ResponsiveContainer>
                            <ComposedChart
                                width={1200}
                                height={225}
                                data={powerDoorData[vesselDoorId]?.data || []}
                                margin={{
                                    top: 10, right: 30, left: 0, bottom: 0,
                                }}
                                barCategoryGap={0}
                                barGap={0}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis 
                                dataKey="date" 
                                tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} />
                                <YAxis 
                                dataKey="differenceFromDock"
                               domain={['auto', 'auto']} 
                                allowDataOverflow={true} />
                                <Tooltip cursor={false} content={<CustomTooltipPower />} />
          
                                <ReferenceLine x={booking.mooringDateTime} stroke="green" label="Mooring Time" />
                                <ReferenceLine x={booking.departureDateTime} stroke="red" label="Departure Time" />
                                <Line
                                    type="monotone"
                                    dataKey="differenceFromDock"
                                    stroke="#8884d8"
                                    dot={false}
                                    strokeWidth={2}
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                        <div className="iconContainer">

                            <button data-html2canvas-ignore type="button" className="graphModalCancel" onClick={closeModal}>
                                <span>&times;</span>
                            </button>
                            <button data-html2canvas-ignore className="graphModalCancel" onClick={() => handleAddToReports(booking.id)}><FaFolderPlus className="addToReportsIcon" /></button>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OperationsMapPowerGraphModal;