import React, { useState } from 'react';
import VesselDataContext from './VesselDataContext';

const VesselDataProvider = ({ children }) => {
    const [vesselData, setVesselData] = useState([]); 

    return (
        <VesselDataContext.Provider value={{ vesselData, setVesselData }}>
            {children}
        </VesselDataContext.Provider>
    );
};

export default VesselDataProvider;