import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Stage, Layer, Image, Group } from 'react-konva';
import useImage from 'use-image';
import { ToastContainer, toast } from 'react-toastify';
import 'moment-timezone';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datetime/css/react-datetime.css";
import UserRoleContext from '../../Contexts/UserRoleContext';
import OperationsMapGraphModal from "./OperationsMapGraphModal";
import OperationsMapPowerGraphModal from "./OperationsMapPowerGraphModal";
import useOperationsMapBookingResources from './useOperationsMapBookingResources';
import OperationsMapShowGraph from "./OperationsMapShowGraphs";
import OperationsMapShowPowerGraph from "./OperationsMapShowPowerGraph";
import { useReportImagesContext } from '../../Contexts/useReportImagesContext';
import OperationsMapBookingTable from './OperationsMapBookingTable';
import {renderDoors, renderPowerDoors} from "./OperationsMapRenderDoors";
import { VesselImage, rotateVessel, fetchVesselImages } from './OperationsMapVesselGroup';
import { saveVesselPosition } from "./OperationsMapSaveVesselPosition";
import { useVesselDataContext } from "../../Contexts/useVesselDataContext";
import OperationsMapGangwayReservations from "./OperationsMapGangwayReservations";
import useRefreshToken from "../../Contexts/useRefreshToken";
import OperationsMapTide from './OperationsMapTide';
import  {Gangway}  from "./OperationsMapPortShapes";
import { ShorePower } from "./OperationsMapShorePowerShapes";

function OperationsMap({ authenticated }) {
console.log("OperationsMap.js authenticated: ", authenticated);


   //const backgroundImageSrc = "/images/CharlottetownPort-original.png";
  const backgroundImageSrc = "/images/InUse/PortCharlottetown-fullhd.jpg";
  //const backgroundImageSrc = "/images/InUse/PortCharlottetown-qhd.jpg";
  //const backgroundImageSrc = "/images/InUse/PortCharlottetown-master.jpg";

  const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
  const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [showGraphsFlag, setShowGraphsFlag] = useState(false);
  const [showPowerGraphFlag, setShowPowerGraphFlag] = useState(false);
  const [selectedEllipseIndex, setSelectedEllipseIndex] = useState({});
  const [selectedPowerEllipseIndex, setSelectedPowerEllipseIndex] = useState({});
  const [backgroundImage] = useImage(backgroundImageSrc);
  const [doorSlopeData13M, setDoorSlopeData13M] = useState({});
  const [doorSlopeData7M, setDoorSlopeData7M] = useState({});
  const [doorSlopeData425M, setDoorSlopeData425M] = useState({});
  const [powerDoorData, setPowerDoorData] = useState({});
  const { vesselData, setVesselData } = useVesselDataContext();
  const [selectedDoor, setSelectedDoor] = useState(null);
  const [vesselImageOrientation, setVesselImageOrientation] = useState({});
  const [selectedVesselId, setSelectedVesselId] = useState(null);

  const [showGraphModal, setShowGraphModal] = useState(false);
  const [showPowerGraphModal, setShowPowerGraphModal] = useState(false);
  const [modalVesselDoorId, setModalVesselDoorId] = useState({});
  const [modalVesselDoorName, setModalVesselDoorName] = useState({});
  const [modalBooking, setModalBooking] = useState({});
  const [modalSlopeData, setModalSlopeData] = useState({});
  const [modalGangwayId, setModalGangwayId] = useState({});
  const [modalGangwayName, setModalGangwayName] = useState({});

  const [doorGangwaySelection, setDoorGangwaySelection] = useState({});
  const { userRole } = useContext(UserRoleContext);
  const [rotationImage] = useImage('/images/Rotate3.png');
  const [saveImage] = useImage('/images/saveDisk.png');
  const { reportImages, setReportImages } = useReportImagesContext();
  const stageRef = useRef();
  const [currentVesselPositions, setCurrentVesselPositions] = useState({});
  const [loading, setLoading] = useState(true);
 
  useRefreshToken();

  const handleDragEnd = (e, vesselBookingId) => {
    const { x, y } = e.target.position();
    setCurrentVesselPositions(prevPositions => ({
      ...prevPositions,
      [vesselBookingId]: { x, y }
    }));
    console.log("Vessel coordinates: ", { x, y })
  };

  const handleSaveVesselPosition = (vesselBookingId, vesselOrientation) => {
    let position = currentVesselPositions[vesselBookingId];

    if (!position) {
      const vessel = vesselData.find(v => v.bookingId === vesselBookingId);
      if (vessel) {
        position = {
          x: imageSize.width / vessel.xCoordinateFactor,
          y: imageSize.height / vessel.yCoordinateFactor
        };
      } else {
        console.error(`Vessel with booking ID ${vesselBookingId} not found`);
        return;
      }
    }
    saveVesselPosition(position.x, position.y, vesselBookingId, imageSize.width, imageSize.height, vesselOrientation);
  };

  const handleGraphModalOpen = (vesselDoorId, vesselDoorName, booking, doorSlopeData, gangwayId, gangwayName) => {
    setModalBooking(booking);
    setModalVesselDoorId(vesselDoorId);
    setModalVesselDoorName(vesselDoorName);
    setModalSlopeData(doorSlopeData);
    setModalGangwayId(gangwayId);
    setModalGangwayName(gangwayName);

    setShowGraphModal(true);
  }

  const handlePowerGraphModalOpen = (vesselDoorId, vesselDoorName, booking, doorSlopeData) => {
    setModalBooking(booking);
    setModalVesselDoorId(vesselDoorId);
    setModalVesselDoorName(vesselDoorName);
    setModalSlopeData(doorSlopeData);
    setShowPowerGraphModal(true);
  }
  const {
    bookingResources,
  } = useOperationsMapBookingResources(token)

  const closeModal = () => {
    setShowGraphModal(false);
    setShowPowerGraphModal(false);  
  };

  useEffect(() => {
    if (backgroundImage) {
      setImageSize({ width: backgroundImage.width, height: backgroundImage.height });
      setLoading(false)
    }
  }, [backgroundImage]);

  if (imageSize.width === 0 || imageSize.height === 0) {
    return null;
  }

  return (

    <div id="capture-container">
     {loading && (
        <div className="loading-spinner">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
       )} 

      <ToastContainer />
     
      <OperationsMapGangwayReservations
        bookingResources={bookingResources}
      />
      {showGraphModal && (
        <OperationsMapGraphModal
          closeModal={closeModal}
          vesselDoorId={modalVesselDoorId}
          vesselDoorName={modalVesselDoorName}
          booking={modalBooking}
          doorSlopeData={modalSlopeData}
          doorGangwaySelection={doorGangwaySelection}
          gangwayId={modalGangwayId}
          gangwayName={modalGangwayName}
        />
      )}
      {showPowerGraphModal && (
        <OperationsMapPowerGraphModal
          closeModal={closeModal}
          vesselDoorId={modalVesselDoorId}
          vesselDoorName={modalVesselDoorName}
          booking={modalBooking}
          powerDoorData={powerDoorData}
        />
      )}
      {showGraphsFlag && selectedVesselId &&
        (
          <OperationsMapShowGraph
            vesselId={selectedVesselId}
            selectedDoor={selectedDoor}
            doorSlopeData13M={doorSlopeData13M}
            doorSlopeData7M={doorSlopeData7M}
            doorSlopeData425M={doorSlopeData425M}
            bookingResources={bookingResources}
            handleGraphModalOpen={handleGraphModalOpen}
          />
        )}
        {showPowerGraphFlag && selectedVesselId &&
        (
          <OperationsMapShowPowerGraph
            vesselId={selectedVesselId}
            selectedDoor={selectedDoor}
            powerDoorData={powerDoorData} 
            bookingResources={bookingResources}
            handlePowerGraphModalOpen={handlePowerGraphModalOpen}
          />
        )}
      <OperationsMapBookingTable
        setReportImages={setReportImages}
        setVesselImageOrientation={setVesselImageOrientation}
      />
      <OperationsMapTide  
      />

      <div className="operationsMapContainerInterior"
        style={{
          height: imageSize.height,
          width: imageSize.width,
        }} >

        <Stage width={imageSize.width} height={imageSize.height} ref={stageRef}>
        
          <Layer>
          
            <Image
              image={backgroundImage}
              x={0}
              y={0}
              width={imageSize.width}
              height={imageSize.height}
              onClick={() => {
                setShowGraphsFlag(false);
                setShowPowerGraphFlag(false);
                setSelectedEllipseIndex({});
                setSelectedPowerEllipseIndex({});
              }}
            />
          </Layer>

          <Layer>
          <Gangway />
          <ShorePower />
            {vesselData.map((vessel, index) => (
              
              <Group
                key={index}
                x={imageSize.width / vessel.xCoordinateFactor}
                y={imageSize.height / vessel.yCoordinateFactor}
                draggable
                onDragEnd={(e) => handleDragEnd(e, vessel.bookingId)}
                rotation={vessel.berth === 'EAST' ? 270 : 0}
              >
                <VesselImage
                  url={vessel.url}
                  x={0}
                  y={0}
                  scaleX={vessel.factor}
                  scaleY={vessel.factor}
                  vesselId={vesselImageOrientation.vesselId}
                  vesselOrientation={vesselImageOrientation.vesselOrientation}
                />
                {vessel.doors ? renderDoors(
                  vesselData,
                  vessel.doors,
                  vessel.factor,
                  vessel.vesselOrientation,
                  vessel.vesselId,
                  setSelectedDoor,
                  setSelectedEllipseIndex,
                  selectedEllipseIndex,
                  bookingResources,
                  setSelectedVesselId,
                  setShowGraphsFlag,
                  setDoorSlopeData13M,
                  setDoorSlopeData7M,
                  setDoorSlopeData425M
                ) : null}
                {vessel.powerDoors ? renderPowerDoors(
                  vesselData,
                  vessel.powerDoors,
                  vessel.factor,
                  vessel.vesselOrientation,
                  vessel.vesselId,
                  setSelectedDoor,
                  setSelectedPowerEllipseIndex,
                  selectedPowerEllipseIndex,
                  bookingResources,
                  setSelectedVesselId,
                  setShowPowerGraphFlag,
                  setPowerDoorData
                ) : null}
                <Image
                  image={rotationImage}
                  width={40}
                  height={40}
                  x={-15}
                  y={20}
                  offsetX={20}
                  offsetY={20}
                  onClick={() => {
                    rotateVessel(vessel.vesselId, vessel.vesselOrientation, setVesselData, userRole, setVesselImageOrientation);
                  }}
                />
                <Image
                  data-html2canvas-ignore
                  image={saveImage}
                  width={25}
                  height={30}
                  x={-5}
                  y={70}
                  offsetX={20}
                  offsetY={20}
                  onClick={() => {
                    handleSaveVesselPosition(vessel.bookingId, vessel.vesselOrientation);
                  }}
                />
              </Group>
            ))}
         </Layer>
        </Stage>

      </div>
    </div>
  );
}
export default OperationsMap;