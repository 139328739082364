import React from 'react'

const GlobalFilter = ({filter, setFilter}) => {
  return (
 <span className="vesselLabels">
    Search: {''}
    <input className="globalFilter" value={filter || ''}
    onChange={(e) => setFilter(e.target.value)}/>

 </span>
  )
}

export default GlobalFilter;