import { Ellipse } from 'react-konva';
import Constants from '../../Constants';
import axios from 'axios';
import { ColorMaps } from "../../utils/ColorMaps";

const handleEllipseClick = (index, door, vesselId, setSelectedDoor, setSelectedEllipseIndex) => {
    setSelectedEllipseIndex(prevState => ({
      ...prevState,
      [vesselId]: index,
    }));
    setSelectedDoor(door);
   // console.log(door)
  };

  const handlePowerEllipseClick = (index, door, vesselId, setSelectedDoor, setSelectedPowerEllipseIndex) => {
    setSelectedPowerEllipseIndex(prevState => ({
      ...prevState,
      [vesselId]: index,
    }));
    setSelectedDoor(door);
   // console.log(door)
  };

  const fetchSlopeData = (mooringDateTime, departureDateTime, doorId, vesselSide, berthId, setDoorSlopeData13M, setDoorSlopeData7M, setDoorSlopeData425M) => {
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const fetchSlopeForEachGangway = (gangwayId, setSlopeDataFunction) => {
      const apiUrlGetSlope = `${Constants.BACK_END}/api/Calculations/slopes/gangwayId/${gangwayId}/mooringDateTime/${mooringDateTime}/departureDateTime/${departureDateTime}/doorId/${doorId}/vesselSide/${vesselSide}/berthId/${berthId}`;
      axios.get(apiUrlGetSlope, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          const processedData = response.data.map(item => ({
            ...item,
            slopeMultiples: [(item.slope + 1) * 10000, (item.slope + 1) * -10000]
          }));
          setSlopeDataFunction(prevState => ({
            ...prevState,
            [doorId]: processedData
          }));
        })
        .catch(error => {
          console.error('Error fetching slopes:', error);
          setSlopeDataFunction(prevState => ({
            ...prevState,
            [doorId]: []
          }));
        });
    }
    fetchSlopeForEachGangway(3, setDoorSlopeData13M);
    fetchSlopeForEachGangway(2, setDoorSlopeData7M);
    fetchSlopeForEachGangway(1, setDoorSlopeData425M);
  }

  const fetchDifferenceFromDockData = (mooringDateTime, departureDateTime, doorId, vesselSide, berthId, setPowerDoorData) => {
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
   
      const apiUrlGetDifferences = `${Constants.BACK_END}/api/Calculations/differences/mooringDateTime/${mooringDateTime}/departureDateTime/${departureDateTime}/doorId/${doorId}/vesselSide/${vesselSide}/berthId/${berthId}`;
      axios.get(apiUrlGetDifferences, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          setPowerDoorData(prevState => ({
            ...prevState,
            [doorId]: response
          }));
        })
        .catch(error => {
          console.error('Error fetching differences:', error);
          setPowerDoorData(prevState => ({
            ...prevState,
            [doorId]: []
          }));
        });
  }


export const renderDoors = (vesselData, 
    doors, 
    scale, 
    vesselOrientation, 
    vesselId, 
    setSelectedDoor, 
    setSelectedEllipseIndex, 
    selectedEllipseIndex,
    bookingResources, 
    setSelectedVesselId, 
    setShowGraphsFlag,
    setDoorSlopeData13M,
    setDoorSlopeData7M,
    setDoorSlopeData425M)  => {
    const vessel = vesselData.find(v => v.vesselId === vesselId);
    if (!vessel) {
      console.error(`Vessel with ID ${vesselId} not found in vesselData.`);
      return null;
    }


    const getColorByGangwayId = (doorId) => {
      const resource = bookingResources.find(resource => resource.vesselDoorId === doorId);
      return resource ? ColorMaps.colorMapId[resource.gangwayId] || "transparent" : "transparent";
    };

    const filteredDoors = doors.filter(door => door.orientation === vesselOrientation);
    return filteredDoors.map((door, index) => (
    
      <Ellipse
        key={index}
        x={scale*door.x}
        y={scale*door.y}
        width={door.width * scale}
        height={door.height * scale}
        fill={
          selectedEllipseIndex[vesselId] === index ? "rgba(255, 255, 0, 0.5)" :  getColorByGangwayId(door.id)
        }
        onClick={(e) => {
          setSelectedEllipseIndex({});
          handleEllipseClick(index, door, vesselId, setSelectedDoor, setSelectedEllipseIndex);
          const stage = e.target.getStage();
          fetchSlopeData(
            vessel.mooringDateTime,
            vessel.departureDateTime,
            door.id,
            door.side,
            vessel.berthId,
            setDoorSlopeData13M,
            setDoorSlopeData7M,
            setDoorSlopeData425M
          );
          setSelectedVesselId(vessel.vesselId);
          setShowGraphsFlag(true);
        }}
      />
    ));
  };


export const renderPowerDoors = (vesselData, 
    powerDoors, 
    scale, 
    vesselOrientation, 
    vesselId, 
    setSelectedDoor, 
    setSelectedPowerEllipseIndex, 
    selectedPowerEllipseIndex,
    bookingResources, 
    setSelectedVesselId, 
    setShowPowerGraphFlag,
    setPowerDoorData)  => {
    const vessel = vesselData.find(v => v.vesselId === vesselId);
    if (!vessel) {
      console.error(`Vessel with ID ${vesselId} not found in vesselData.`);
      return null;
    }

    const getColorByGangwayId = (doorId) => {
      const resource = bookingResources.find(resource => resource.vesselDoorId === doorId);
      return resource ? ColorMaps.colorMapId[resource.gangwayId] || "transparent" : "transparent";
    };

    const filteredDoors = powerDoors.filter(door => door.orientation === vesselOrientation);
    //console.log("powerDoors", powerDoors);
    return filteredDoors.map((door, index) => (
    <Ellipse
        key={index}
        x={scale*door.x}
        y={scale*door.y}
        width={door.width * scale}
        height={door.height * scale}
        fill={
          selectedPowerEllipseIndex[vesselId] === index
            ? "rgba(255, 255, 0, 0.5)"
            :  getColorByGangwayId(door.id)
        }
        onClick={(e) => {
          setSelectedPowerEllipseIndex({});
          handlePowerEllipseClick(index, door, vesselId, setSelectedDoor, setSelectedPowerEllipseIndex);
          const stage = e.target.getStage();
          fetchDifferenceFromDockData(
            vessel.mooringDateTime,
            vessel.departureDateTime,
            door.id,
            door.side,
            vessel.berthId,
            setPowerDoorData
          );
          setSelectedVesselId(vessel.vesselId);
          setShowPowerGraphFlag(true);
        }}
      />
    ));
  };

  //export default renderDoors;