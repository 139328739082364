import React, { useState, useEffect, useContext } from "react";
import {
    AreaChart, Label, ReferenceArea, ComposedChart, Bar, BarChart, Cell, LineChart,
    Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine
} from "recharts";
import 'moment-timezone';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datetime/css/react-datetime.css";
import {CustomTooltipPower} from "./OperationsMapCustomToolTip";
import { getColorBasedOnValue } from "./OperationsMapGetColorUtil";
import { useVesselDataContext } from "../../Contexts/useVesselDataContext";



const OperationsMapShowPowerGraph = ({ vesselId, selectedDoor, powerDoorData, bookingResources, handlePowerGraphModalOpen }) => {

// useEffect(() => {
//     console.log("powerDoorData: ", powerDoorData);
// }, [powerDoorData]);

    const { vesselData, setVesselData } = useVesselDataContext();

    const vessel = vesselData.find(v => v.vesselId === vesselId);

    if (!vessel) {
        console.error(`Vessel with id ${vesselId} not found in vesselData`, vesselData);
        return null;
    }

    if (vessel.berthId === 4) {
        return null;
    }

    const berthMap = {
        1: "South East",
        2: "South West",
        3: "East",
        4: "Tender"
    };
    const positionMap = {
        1: { x: 1545, y: 350 },
        2: { x: 130, y: 350 },
        3: { x: 1545, y: 100 },
    };
    const berthName = berthMap[vessel.berthId] || "Berth Unknown";
    const position = positionMap[vessel.berthId] || { x: 0, y: 0 };

    return (
        <div
            className={`operationsPictureShowGraphs ${berthName.replace(/\s+/g, '').toLowerCase()}`}
            style={{
                left: position.x,
                top: position.y,
            }}
        >    
            <div className="chartTitle"> Shore Connection Door Meters from Dock </div>
            <div className="chartTitle"> Berth: {[berthName]} </div>

            <ComposedChart
                width={300}
                height={100}
                data={powerDoorData[selectedDoor.id]?.data || []}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}
                barCategoryGap={0}
                barGap={0}
                onClick={() => handlePowerGraphModalOpen(selectedDoor.id, selectedDoor.name, vessel, powerDoorData)}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="date"
                    tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                    interval={15}
                    tick={{ fontSize: 12 }}
                />
                <YAxis 
                dataKey="differenceFromDock"
                domain={['auto', 'auto']} 
                allowDataOverflow={true}
                    tick={{ fontSize: 12 }}
                />
                <Tooltip cursor={false} content={<CustomTooltipPower />} />

                <ReferenceLine x={vessel.mooringDateTime} stroke="green" label="MT" />
                <ReferenceLine x={vessel.departureDateTime} stroke="red" label="DT" />
                <Line
                    type="monotone"
                    dataKey="differenceFromDock"
                    stroke="#8884d8"
                    dot={false}
                    strokeWidth={2}
                />
            </ComposedChart>
        </div>
    );
};

export default OperationsMapShowPowerGraph;