import React from 'react';

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const formattedDate = new Date(label).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    const slope = payload[1]?.value;
    const formattedSlope = slope ? slope.toFixed(2) : 'N/A';

    return (
      <div className="customTooltip">
        {`Time: ${formattedDate}    `}
        {`Slope: ${formattedSlope}`}
      </div>
    );
  }

  return null;
};

export const CustomTooltipPower = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const formattedDate = new Date(label).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    const differenceFromDock = payload[0]?.value;
    const formattedDifferenceFromDock = differenceFromDock ? `${differenceFromDock.toFixed(2)} meters` : 'N/A';

    return (
      <div className="customTooltip">
<div>{`Time: ${formattedDate}`}</div>
<div>{`Dif. Fr. Dock: ${formattedDifferenceFromDock}`}</div>
      </div>
    );
  }
  
  return null;
};

