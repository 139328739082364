import React, { useContext, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import UserRoleContext from '../../Contexts/UserRoleContext';
import Constants from '../../Constants';
import moment from 'moment';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import handleError from "./OperationsMapHandleError";


const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
const fingerprint = sessionStorage.getItem("fingerprint") || localStorage.getItem("fingerprint");


const handleDateChange = (
  date,
  userRole,
  userPortId,
  setBookings,
  setSelectedDate,
  fetchVesselImages,
  fetchBookingResources,
  setVesselData,
  setVesselImageOrientation,
  token,
  setTide,
  setBookingResources
) => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  setSelectedDate(date);
  fetchBookingListSelectedDate(formattedDate,
    userRole,
    userPortId,
    setBookings,
    fetchVesselImages,
    fetchBookingResources,
    setVesselData,
    setVesselImageOrientation,
    token,
    setBookingResources
  );
  fetchTideSelectedDate(formattedDate, setTide)
}

const fetchTideSelectedDate = (date, setTide) => {
  //console.log("hello from operations map date, fetchTideSelectedDate, date: ", date)
  const formattedDate = moment(date).format('YYYY-MM-DD');
  //console.log("hello from operations map date, fetchTideSelectedDate, date: ", formattedDate)
  axios.get(`${Constants.BACK_END}/api/Tide/${formattedDate}`, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => {
      setTide(response.data);
    })
    .catch(error => {
      console.error('Error fetching tide information:', error);
    });
}

const fetchBookingListSelectedDate = (
  date,
  userRole,
  userPortId,
  setBookings,
  fetchVesselImages,
  fetchBookingResources,
  setVesselData,
  setVesselImageOrientation,
  token,
  setBookingResources) => {
  const apiUrlSelectedDate = `${Constants.BACK_END}/api/Booking/date/${date}/portId/${userPortId}`;
  axios.get(userRole === "User" ? "" : apiUrlSelectedDate, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Fingerprint': fingerprint
    }
  })
    .then(response => {
      setBookings(response.data);
      //  response.data.forEach(booking => fetchBookingResources(booking.id))
        console.log("hello from fetchBookingListSelectedDate bookings: .", response.data)
      // Check if `response.data` is empty or undefined
      if (!response.data || response.data.length === 0) {
        //console.log("hello");
        setBookingResources([]);
      } else {
        // Call fetchBookingResources for each booking ID
        response.data.forEach(booking => fetchBookingResources(booking.id));
      }

      const vesselData1 = response.data.map(booking => ({
        berth: booking.berthName,
        berthId: booking.berthId,
        vesselId: booking.vesselId,
        vesselOrientation: booking.vesselOrientation,
        bookingId: booking.id,
        mooringDateTime: booking.mooringDateTime,
        departureDateTime: booking.departureDateTime,
        xCoordinateFactor: booking.xCoordinateFactor,
        yCoordinateFactor: booking.yCoordinateFactor
      }));
      Promise.all(vesselData1.map(booking =>
        fetchVesselImages(booking, userRole, setVesselImageOrientation)
      ))
        .then(results => {
          setVesselData(results.flat());
        })
        .catch(error => {
          handleError(error, 'Fetching Vessel Images');
        });
    })
    .catch(error => {
      handleError(error, 'Getting Bookings');
      toast.error("There was an error fetching the bookings.")
    });
};



export { handleDateChange }