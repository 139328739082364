import 'bootstrap/dist/css/bootstrap.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import "react-datetime/css/react-datetime.css";
import 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrash, FaSave, FaCalendarCheck } from "react-icons/fa";
import { ComposedChart, Bar, BarChart, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from "recharts";
import OperationsBookingResources from './OperationsBookingResources';
import { useBookingResourcesContext } from "../../Contexts/useBookingResourcesContext";
import { FaFolderPlus } from "react-icons/fa";
import html2canvas from 'html2canvas';
import { useReportImagesContext } from '../../Contexts/useReportImagesContext';
import axios from 'axios';
import DateTime from 'react-datetime';
import Constants from '../../Constants';
import moment from 'moment';
import UserRoleContext from '../../Contexts/UserRoleContext';
import OperationsGangwayReservationForm from './OperationsGangwayReservationForm';


function OperationsGraphModal(
    {
        closeModal,
        getColorBasedOnValue,
        CustomTooltip,
        doorSlopeData,
        vesselDoor,
        booking,
        handleGangwayReservation,
        doorGangwaySelection,
        selectedBerths,
        gangwayId,
        gangwayName }) {

    const modalRef = useRef(null);

    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");

    const [showGangwayReservation, setShowGangwayReservation] = useState (false);

    const {
        handleRemoveBookingResource,
        handleSelectBookingResource,
        handleUpdateBookingResource,
        fetchBookingResources,
        hasBookingResource
    } = OperationsBookingResources(token)

    const { bookingResources, setBookingResources } = useBookingResourcesContext();
    const { reportImages, setReportImages } = useReportImagesContext();

    const handleAddToReports = (bookingId) => {
        const container = document.getElementById(`capture-container3-${bookingId}`);
        console.log("hello from handleaddtoreports")
        html2canvas(container, {
            scale: 1,
            useCORS: true,
        }).then(canvas => {
            const dataURL = canvas.toDataURL();
            try {
                // Check if session storage is supported
                if (typeof (Storage) !== "undefined") {
                    // Function to estimate the size of the dataURL
                    const estimateSize = (data) => {
                        return (data.length * 3 / 4) - (data.split('=').length - 1);
                    };
                    const dataSize = estimateSize(dataURL);
                    const storageQuota = 5 * 1024 * 1024; // Example: 5MB quota
                    const currentSize = Object.keys(sessionStorage).reduce((acc, key) => {
                        return acc + estimateSize(sessionStorage.getItem(key));
                    }, 0);
                    console.log(currentSize)
                    if (currentSize + dataSize > storageQuota) {
                        throw new Error("Session storage quota exceeded.");
                    }
                    // Attempt to add the dataURL to session storage
                    const testKey = "testKey";
                    sessionStorage.setItem(testKey, dataURL);
                    sessionStorage.removeItem(testKey); // Clean up after the test
                    // If successful, update the report images context
                    setReportImages(prevImages => [...prevImages, dataURL]);
                } else {
                    throw new Error("Session storage is not supported.");
                }
            } catch (error) {
                console.error("Error adding report image to session storage:", error);
                toast.error("Could not add image to storage. Try deleting other images.");
            }
        }).catch(error => {
            console.error("Error capturing screenshot:", error);
            toast.error("Failed to capture screenshot. Please try again later.");
        });
    }

    useEffect(() => {
        let handler = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                closeModal()
            }
        };
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [closeModal]);

    useEffect(() => {
        console.log("from OGR, booking resources: ", bookingResources)
    }, [bookingResources])

    /////////////////////////////

    const isResourceFound13M = bookingResources.some(resource =>
        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 3);
    const foundResource13M = bookingResources.find(resource =>
        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 3);
    const isResourceFound7M = bookingResources.some(resource =>
        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 2);
    const foundResource7M = bookingResources.find(resource =>
        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 2);
    const isResourceFound425M = bookingResources.some(resource =>
        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 1);
    const foundResource425M = bookingResources.find(resource =>
        resource.bookingId === booking.id && resource.vesselDoorId === vesselDoor.id && resource.gangwayId === 1);

    return (

        <div className="graphModalContainer"  >
            {/* <ToastContainer /> */}
            <div className="operationsGraphModal" ref={modalRef} id={`capture-container3-${booking.id}`}>

                <p>Vessel Door Id: {[vesselDoor.id]}, Gangway: {gangwayName}</p>
                <div className="chartContainer">

                    <ResponsiveContainer>
                        <ComposedChart
                            // width={1200}
                            // height={225}
                            data={doorSlopeData[vesselDoor.id] || []}
                            margin={{
                                top: 10, right: 30, left: 0, bottom: 0,
                            }}
                            barCategoryGap={0}
                            barGap={0}

                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} />
                            <YAxis domain={[-40, 40]} allowDataOverflow={true} />
                            <Tooltip cursor={false} content={<CustomTooltip />} />
                            <Bar
                                dataKey="slopeMultiples"
                                fill={(entry) => getColorBasedOnValue(entry.slope)}
                                label={{ position: 'top' }}
                                barSize={17}
                            >
                                {doorSlopeData[vesselDoor.id]?.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={getColorBasedOnValue(entry.slope)}
                                        stroke={getColorBasedOnValue(entry.slope)}
                                        strokeWidth={20}
                                    />
                                ))}
                            </Bar>
                            <ReferenceLine x={booking.mooringDateTime} stroke="green" label="Mooring Time" />
                            <ReferenceLine x={booking.departureDateTime} stroke="red" label="Departure Time" />
                            <Line
                                type="monotone"
                                dataKey="slope"
                                stroke="#8884d8"
                                dot={false}
                                strokeWidth={2}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>

                    <div className="iconContainer">
                        <button data-html2canvas-ignore type="button" className="graphModalCancel" onClick={closeModal}>
                            <span>&times;</span>
                        </button>
                        <button data-html2canvas-ignore className="graphModalCancel" onClick={() => handleAddToReports(booking.id)}><FaFolderPlus className="addToReportsIcon" /></button>

                        {isResourceFound13M && gangwayId === 2 || isResourceFound7M && gangwayId === 3 || isResourceFound425M && gangwayId === 4 ? (
                            <>
                                {isResourceFound13M && gangwayId === 2 && (
                                    <button data-html2canvas-ignore className="btn delete" onClick={() => handleRemoveBookingResource(booking.id, vesselDoor.id, foundResource13M?.gangwayId)}>
                                        <FaTrash className='deleteIcon' />
                                    </button>
                                )}
                                {isResourceFound7M && gangwayId === 3 && (
                                    <button data-html2canvas-ignore className="btn delete" onClick={() => handleRemoveBookingResource(booking.id, vesselDoor.id, foundResource7M?.gangwayId)}>
                                        <FaTrash className='deleteIcon' />
                                    </button>
                                )}
                                {isResourceFound425M && gangwayId === 4 && (
                                    <button data-html2canvas-ignore className="btn delete" onClick={() => handleRemoveBookingResource(booking.id, vesselDoor.id, foundResource425M?.gangwayId)}>
                                        <FaTrash className='deleteIcon' />
                                    </button>
                                )}
                                {isResourceFound13M && gangwayId === 2 && (
                                    <button data-html2canvas-ignore className="btn delete" onClick={() => handleGangwayReservation(booking.id, foundResource13M?.gangwayId, booking.mooringDateTime, booking.departureDateTime)}>
                                        <FaCalendarCheck className='deleteIcon' />
                                    </button>
                                )}
                                {isResourceFound7M && gangwayId === 3 && (
                                    <button data-html2canvas-ignore className="btn delete" onClick={() => handleGangwayReservation(booking.id, foundResource7M?.gangwayId, booking.mooringDateTime, booking.departureDateTime)}>
                                        <FaCalendarCheck className='deleteIcon' />
                                    </button>
                                )}
                                {isResourceFound425M && gangwayId === 4 && (
                                    <button data-html2canvas-ignore className="btn delete" onClick={() => handleGangwayReservation(booking.id, foundResource425M?.gangwayId, booking.mooringDateTime, booking.departureDateTime)}>
                                        <FaCalendarCheck className='deleteIcon' />
                                    </button>
                                )}
                            </>
                        ) : (
                            <button data-html2canvas-ignore className="btn save" 
                            onClick={() => {
                                //handleSelectBookingResource(booking.id, vesselDoor.id, gangwayId, selectedBerths[booking.id])
                                setShowGangwayReservation(true)
                                }}>
                                <FaSave className='deleteIcon' />
                            </button>
                        )}
                    </div>

                </div>
                {showGangwayReservation ? (
                <OperationsGangwayReservationForm
                            closeModal={closeModal}
                            bookingId={booking.id}
                            gangwayId={gangwayId}
                            vesselDoorId={vesselDoor.id}
                            mooringDateTime={booking.mooringDateTime}
                            departureDateTime={booking.departureDateTime}
                        />
                    ) : (<div/>)}


            </div>
        </div>

    )

}

export default OperationsGraphModal;