
const ColorMaps = {
    colorMapId: {
      '2': 'rgba(156, 39, 176, 0.5)', 
      '3': 'rgba(67, 249, 255, 0.5)', 
      '4': 'rgba(34, 65, 138, 0.5)'
    },
    colorMapName: {
        '13M': '#9C27B0', 
        '7M': '#43f9ff', 
        '4.25M': '#22418a'
      }
  };
  
export { ColorMaps };
  