import React, { useContext, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Constants from '../../Constants';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");

export const saveVesselPosition = async (xCoor, yCoor, bookingId, imageWidth, imageHeight, vesselOrientation) =>{

    const xCoordinateFactor = (imageWidth / xCoor)
    const yCoordinateFactor = (imageHeight / yCoor)
    
    try {
      const response = await axios.put(`${Constants.BACK_END}/api/Booking/updateFactors/${bookingId}/${xCoordinateFactor}/${yCoordinateFactor}/${vesselOrientation}`, {}, {
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
      console.log("Booking updated successfully", response);
      //alert('Booking updated successfully');
      toast.success('Booking updated successfully', {
          position: "top-center",
          hideProgressBar: true
      });
  } catch (error) {
      const errorMessage = error.response?.data || 'An error occurred';
      toast.error(`Error updating booking: ${errorMessage}`, {
          position: "top-center",
          hideProgressBar: true
      });
      console.error('Error updating booking:', error);
  }
};
