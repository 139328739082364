import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import Constants from '../../Constants';
import '../../App.css'
import './Vessel.css'
import {Link, useLocation, useParams} from 'react-router-dom';
import DateTime from "react-datetime";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import VesselDoors from './VesselDoors';
import {FaRegSquarePlus} from "react-icons/fa6";
import GlobalFilter from "./VesselsTableGlobalFilter";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {checkAuthenticateIssue} from "../Login/LoginUtil";

function VesselDetails() {
    const {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {vessel} = location.state || {};

    const [selectedVessel, setSelectedVessel] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(true);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});

    const [debugging, setDebugging] = useState(false);

    const cruiseLines = [
        {
            name: 'P&O Cruises'
        },
        {
            name: 'Princess Cruises'
        },
        {
            name: 'Holland America Line'
        },
    ]

    const cruiseLineParents = [
        {
            name: 'Carnival Corporation & PLC'
        }
    ]

    const categories = [
        {
            name: 'Cruise'
        }, {
            name: 'Oil Tanker'
        }, {
            name: 'Potatoe Tanker'
        }, {
            name: 'Navy'
        },
    ]

    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        crew: 0,
        pax: 0,
        length: 0,
        width: 0,
        tonnage: 0,
        cruiseLine: '',
        cruiseLineParent: '',
        category: '',
        visits: 0,
        Loa: 0,
        beam: 0,
        name2: '',
        notes: ''
    });

    useEffect(() => {
        //save name to be sure cant ypdate to an existing name

        if (id == 'add') {
            if (debugging) window.alert("have to add one")
            setLoading(false);
            setUpdating(false);
        } else {
            console.log("BJTEST" + Constants.BACK_END + `/api/Vessel/${id}`);
            axios.get(Constants.BACK_END + `/api/Vessel/${id}`)
                .then(response => {
                    console.log('Data fetched:', response.data); // Log the response data
                    const vessel = response.data;
                    setSelectedVessel(vessel);
                    setFormData({
                        id: vessel.id,
                        name: vessel.name,
                        crew: vessel.crew,
                        pax: vessel.pax,
                        length: vessel.length,
                        width: vessel.width,
                        tonnage: vessel.tonnage,
                        cruiseLine: vessel.cruiseLine,
                        cruiseLineParent: vessel.cruiseLineParent,
                        category: vessel.category,
                        visits: vessel.visits,
                        loa: vessel.loa,
                        beam: vessel.beam,
                        name2: vessel.name2,
                        notes: vessel.notes
                    });
                    setLoading(false);
                })
                .catch(error => {
                    console.error('There was an error fetching the data!', error);
                    setError(error);
                    setLoading(false);
                    if(checkAuthenticateIssue(error)){
                        navigate("/Login")
                    };
                });
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleChange = (e) => {
        //window.alert("Handling a change");
        const {id, value} = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [id]: value
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = 'Name is required';
        }
        if (!formData.crew) {
            newErrors.crew = 'Crew is required';
        }
        if (!formData.pax) {
            newErrors.pax = 'Pax is required';
        }
        if (!formData.length) {
            newErrors.length = 'Length is required';
        }
        if (!formData.width) {
            newErrors.width = 'Width is required';
        }
        if (!formData.category) {
            newErrors.category = 'Category is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            console.log('Form Data:', selectedVessel);
            if (updating) {
                if (debugging) window.alert("about to put");
                axios.put(Constants.BACK_END + `/api/Vessel`, formData)
                    .then(response => {
                        console.log('Updated successfully', response.data);
                        if (debugging) window.alert('Updated successfully'); // Display pop-up message

                        toast.success('Updated.', {
                            position: "top-center",
                            hideProgressBar: true
                        });


                    })
                    .catch(error => {
                        if (error.response) {
                            // Server responded with a status other than 200 range
                            console.error('Error response data:', error.response.data);
                            console.error('Error response status:', error.response.status);
                            console.error('Error response headers:', error.response.headers);
                            toast.error('Error adding: ' + error.response.data.message || error.message, {
                                position: "top-center",
                                hideProgressBar: true
                            });
                            //window.alert('Error adding: ' + error.response.data.message || error.message);
                        } else if (error.request) {
                            // Request was made but no response received
                            console.error('Error request:', error.request);
                            //window.alert('Error adding: No response received from the server.');
                            toast.error('Error adding: No response received from the server.', {
                                position: "top-center",
                                hideProgressBar: true
                            });
                        } else {
                            // Something else caused the error
                            console.error('Error message:', error.message);
                            toast.error('Error adding: ' + error.message, {
                                position: "top-center",
                                hideProgressBar: true
                            });
                        }
                        console.error('Error config:', error.config);
                    });
            } else {

                //verify that the name isn't already taken


                if (debugging) window.alert("about to post");
                axios.post(Constants.BACK_END + `/api/Vessel`, formData)
                    .then(response => {
                        console.log('Added successfully', response.data);
                        window.alert('Added successfully' + response.data); // Display pop-up message
                        setUpdating(true);
                        navigate("/vessels");
                    })
                    .catch(error => {

                        if (error.response) {
                            // Server responded with a status other than 200 range
                            console.error('Error response data:', error.response.data);
                            console.error('Error response status:', error.response.status);
                            console.error('Error response headers:', error.response.headers);
                            //window.alert('Error adding: ' + error.response.data.message || error.message);
                            toast.error('Error adding: ' + error.response.data.message || error.message, {
                                position: "top-center",
                                hideProgressBar: true
                            });

                        } else if (error.request) {
                            // Request was made but no response received
                            console.error('Error request:', error.request);
                            //window.alert('Error adding: No response received from the server.');
                            toast.error('Error adding: No response received from the server.', {
                                position: "top-center",
                                hideProgressBar: true
                            });
                        } else {
                            // Something else caused the error
                            console.error('Error message:', error.message);
                            //window.alert('Error adding: ' + error.message);
                            toast.error('Error adding: ' + error.message, {
                                position: "top-center",
                                hideProgressBar: true
                            });
                        }
                        console.error('Error config:', error.config);
                    });
            }
        } else {
            toast.error('Error - please enter all required fields', {
                position: "top-center",
                hideProgressBar: true
            });
        }
    };


    return (
        <div>
            <div className='top-container'>
                <div className='top-container-inside'>
                    {updating ? (
                        <h1 className="bookingHeader">Vessel: {formData.name}</h1>
                    ) : <h1 className="bookingHeader">Add Vessel</h1>}

                </div>

                <div className='top-container-inside'>

                    <div className='top-container-interior'>
                    </div>

                    <div className='top-container-interior'>
                    </div>

                    <div className='top-container-interior'>
                    </div>

                </div>
            </div>

            <div className='table-container'>
                <form className="row g-1" id="updateForm" onSubmit={handleSubmit}>
                    <div className="col-md-6 px-3">
                        <label htmlFor="name">Name<span className="required">*</span></label>
                        <input
                            type="hidden"
                            className="form-control"
                            id="id"
                            type="hidden"
                            value={formData.id}
                        />
                        <input
                            type="text"
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            id="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>

                    <div className="col-md-6 px-3">
                        <label htmlFor="vesselCrew">Crew<span className="required">*</span></label>
                        <input
                            type="number"
                            className={`form-control ${errors.crew ? 'is-invalid' : ''}`}
                            id="crew"
                            placeholder="Crew"
                            value={formData.crew}
                            onChange={handleChange}
                        />
                        {errors.crew && <div className="invalid-feedback">{errors.crew}</div>}
                    </div>

                    <div className="col-md-6 px-3">
                        <label htmlFor="pax">PAX<span className="required">*</span></label>
                        <input
                            type="number"
                            className={`form-control ${errors.pax ? 'is-invalid' : ''}`}
                            id="pax"
                            placeholder="Pax"
                            value={formData.pax}
                            onChange={handleChange}
                        />
                        {errors.pax && <div className="invalid-feedback">{errors.pax}</div>}
                    </div>

                    <div className="col-md-6 px-3">
                        <label htmlFor="length">Length<span className="required">*</span></label>
                        <input
                            type="number"
                            className={`form-control ${errors.length ? 'is-invalid' : ''}`}
                            id="length"
                            placeholder="vessel length"
                            value={formData.length}
                            onChange={handleChange}
                        />
                        {errors.length && <div className="invalid-feedback">{errors.length}</div>}
                    </div>

                    <div className="col-md-6 px-3">
                        <label htmlFor="width">Width<span className="required">*</span></label>
                        <input
                            type="number"
                            className={`form-control ${errors.width ? 'is-invalid' : ''}`}
                            id="width"
                            placeholder="width"
                            value={formData.width}
                            onChange={handleChange}
                        />
                        {errors.width && <div className="invalid-feedback">{errors.width}</div>}
                    </div>

                    <div className="col-md-6 px-3">
                        <label htmlFor="category">Category<span className="required">*</span></label>
                        <select
                            className={`form-control ${errors.category ? 'is-invalid' : ''}`}
                            id="category"
                            value={formData.category}
                            onChange={handleChange}
                        >
                            <option value="">Select Category</option>
                            {categories.map((category, index) => (
                                <option key={index} value={category.name}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                        {errors.category && <div className="invalid-feedback">{errors.category}</div>}
                    </div>


                    <div className="col-md-6 px-3">
                        <label htmlFor="cruiseLine">Cruise Line</label>
                        <select
                            className={`form-control ${errors.cruiseLine ? 'is-invalid' : ''}`}
                            id="cruiseLine"
                            value={formData.cruiseLine}
                            onChange={handleChange}
                        >
                            <option value="">Select Cruise Line</option>
                            {cruiseLines.map((cruiseLine, index) => (
                                <option key={index} value={cruiseLine.name}>
                                    {cruiseLine.name}
                                </option>
                            ))}
                        </select>
                        {errors.cruiseLine && <div className="invalid-feedback">{errors.cruiseLine}</div>}
                    </div>

                    <div className="col-md-6 px-3">
                        <label htmlFor="cruiseLineParent">Cruise Line Parent </label>
                        <select
                            className={`form-control ${errors.cruiseLineParent ? 'is-invalid' : ''}`}
                            id="cruiseLineParent"
                            value={formData.cruiseLineParent}
                            onChange={handleChange}
                        >
                            <option value="">Select Cruise Line Parent</option>
                            {cruiseLineParents.map((cruiseLineParent, index) => (
                                <option key={index} value={cruiseLineParent.name}>
                                    {cruiseLineParent.name}
                                </option>
                            ))}
                        </select>
                        {errors.cruiseLineParent && <div className="invalid-feedback">{errors.cruiseLineParent}</div>}
                    </div>

                    <div className="col-md-6 px-3">
                        <label htmlFor="tonnage">Tonnage</label>
                        <input
                            type="number"
                            className="form-control"
                            id="tonnage"
                            placeholder="tonnage"
                            value={formData.tonnage}
                            onChange={handleChange}
                        />
                    </div>


                    <div className="col-md-6 px-3">
                        <label htmlFor="visits">Visits</label>
                        <input
                            type="text"
                            className="form-control"
                            id="visits"
                            placeholder="visits"
                            value={formData.visits}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="loa">LOA</label>
                        <input
                            type="text"
                            className="form-control"
                            id="loa"
                            placeholder="LOA"
                            value={formData.loa}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="beam">Beam</label>
                        <input
                            type="text"
                            className="form-control"
                            id="beam"
                            placeholder="beam"
                            value={formData.beam}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="name2">Name 2</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name2"
                            placeholder="name2"
                            value={formData.name2}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="col-md-6 px-3">
                        <label htmlFor="notes">Notes</label>
                        <input
                            type="text"
                            className="form-control"
                            id="notes"
                            placeholder="notes"
                            value={formData.notes}
                            onChange={handleChange}
                        />
                    </div>
                    {/*<div class="container">*/}
                    <div className="row">
                        <button type="submit"
                                className="col-md-12 btn btn-primary button-margin">{updating ? 'Update' : 'Add'}</button>

                        <ToastContainer/>
                    </div>
                    <div className="row">
                        <Link
                            to={{
                                pathname: `/Vessels`,
                                state: {}
                            }}
                        >
                            <button className="col-md-12 btn btn-warning button-margin">Return</button>
                        </Link>
                    </div>
                </form>

            </div>

            {updating ? (
                <VesselDoors formData={formData}/>
            ) : null}

        </div>
    );
}

export default VesselDetails;


