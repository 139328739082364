import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {useUserRoleContext} from '../Contexts/useUserRoleContext';
import { FaBookOpen, FaHome, FaShip, FaChartLine, FaMap, FaFolder } from "react-icons/fa";
import { MdLogin, MdLogout } from "react-icons/md";


const Header = React.memo(({ isAuthenticated, logout }) => {
  const navigate = useNavigate();
  const {userRole, setUserRole} = useUserRoleContext();

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    sessionStorage.removeItem('jwtToken');
    localStorage.removeItem('userRole');
    sessionStorage.removeItem('userRole');
    setUserRole(null);
    logout();
    navigate('/login');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  console.log('userRole from header ' + userRole);

  // if (userRole === null) {
  //   // Optionally, render a loading state or nothing while waiting for userRole
  //   console.log("null user role")
  //   return <div></div>;
  // }

  return (
    <nav className="navbar navbar-expand-lg ">
      <div className="container-fluid">
        <button className="btn navHome" onClick={() => navigate('/Home')}>
          <FaHome className="homeIcon"/>
        </button>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button> 
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {isAuthenticated ? (
              <>
                <li className="nav-item">
                  <button className="btn navBookings" onClick={() => navigate('/Bookings')}>
                    <FaBookOpen className="bookingsIcon"/>
                  </button>
                </li>
                {userRole === "PortAdmin" &&
                  <li className="nav-item">
                    <button className="btn navVessels" onClick={() => navigate('/Vessels')}>
                      <FaShip className="vesselsIcon"/>
                    </button>
                  </li>
                }
                <li className="nav-item">
                  <button className="btn navOperations" onClick={() => navigate('/Operations')}>
                    <FaChartLine className="operationsIcon"/>
                  </button>
                </li>
                <li className="nav-item">
                  <button className="btn navOperationsMap" onClick={() =>navigate('/operationsMap')}>
                    <FaMap className="operationsMapIcon"/>
                  </button>
                </li>
                <li className="nav-item">
                  <button className="btn reports" onClick={() => navigate('/reports')}>
                    <FaFolder  className="reportsIcon"/>
                  </button>
                </li>
                <li className="nav-item"><button className="btn logout" type="button" onClick={handleLogout}><MdLogout className="logoutIcon"/></button></li>
              </>
            ) : (
              <li className="nav-item">
                <button className="btn login" type="button" onClick={handleLogin}><MdLogin className="loginIcon"/></button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
});

export default Header;
