import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "7d021f6b-1e83-4b02-9dd2-13698d76069b",
    authority: "https://login.microsoftonline.com/78d4545c-43b0-4a7e-b4cb-d91916d6b1cd",
    redirectUri: process.env.NODE_ENV === 'development' 
      ? "http://localhost:3000" 
      : "https://www.portcon.ca",
  }
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
