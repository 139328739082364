import 'bootstrap/dist/css/bootstrap.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import axios, { formToJSON } from 'axios';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserRoleContext from '../../Contexts/UserRoleContext';
import Constants from '../../Constants';
import {checkAuthenticateIssue} from "../Login/LoginUtil";
import {useNavigate} from "react-router-dom";


function BookingsUpdateModal({ booking, closeModal, fetchBookingListThisYear, clearSelections, selectDelete }) {

  const [vesselNames, setVesselNames] = useState([]);
  const [berthNames, setBerthNames] = useState([]);
  const [bookingDateError, setBookingDateError] = useState(false);
  const [errors, setErrors] = useState({});
  const [showBookingDeleteModal, setShowBookingDeleteModal] = useState(false);
  const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
  const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
  const { userRole } = useContext(UserRoleContext);
  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    id: '',
    vesselName: '',
    vesselOrientation:'',
    mooringDateTime: '',
    departureDateTime: '',
    status: '',
    cancellationReason: '',
    specialNote: '',
    contactDateTime: '',
    agent: '',
    portId: '',
    berthName: ''
  });

  useEffect(() => {
    if (booking) {
      console.log('Booking vessel name:', booking.vesselName);
      setFormData({
        id: booking.id,
        vesselName: booking.vesselName,
        vesselOrientation: booking.vesselOrientation,
        mooringDateTime: new Date(booking.mooringDateTime),
        departureDateTime: new Date(booking.departureDateTime),
        status: booking.status,
        cancellationReason: booking.cancellationReason,
        specialNote: booking.specialNote,
        contactDateTime: new Date(booking.contactDateTime),
        agent: booking.agent,
        portId: booking.portId,
        berthName: booking.berthName
      });
    }
  }, [booking]);

  const modalRef = useRef(null);
  
  useEffect(() => {
      let handler = (e) => {
          if (modalRef.current && !modalRef.current.contains(e.target)) {
              closeModal()
          }
      };
      if (!showBookingDeleteModal) {
        document.addEventListener("mousedown", handler);
    }
      return () => {
          document.removeEventListener('mousedown', handler);
      };
  }, [closeModal, showBookingDeleteModal]);

  useEffect(() => {
    const fetchBerthNames = async () => {
      try{
        const response = await axios.get(`${Constants.BACK_END}/api/Berth/${userPortId}`, { 
          headers: { Authorization: `Bearer ${token}` } 
        });
        setBerthNames(response.data);
      }
       catch(error) {
        console.error('Error fetching berth names:', error);
        if(checkAuthenticateIssue(error)){
          navigate("/Login")
        };
      }
      };
      fetchBerthNames();
  }, [userPortId, token]);

  useEffect(() => {
    const fetchVesselNames = async () => {
      try {
        const response = await axios.get(`${Constants.BACK_END}/api/Vessel`, { 
          headers: { Authorization: `Bearer ${token}` } 
        });
        setVesselNames(response.data);
      } catch (error) {
        console.error('Error fetching berth names:', error);
        if (checkAuthenticateIssue(error)) {
          navigate("/Login")
        }
      }
    };
    fetchVesselNames();
  }, [userPortId, token]);


  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'status' && value === 'CANCELLED') {
      setFormData(prevFormData => ({
        ...prevFormData,
        berthName: '',
        [id]: value
      }))
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [id]: value
      }));
    }
  };

  const handleDateChange = (date, id) => {
    const localDate = moment(date).format();

    setFormData(prevFormData => {
      const newFormData = { ...prevFormData, [id]:localDate };
     // console.log("The old form data is: ", {prevFormData});
     // console.log("The new form data is: ", {newFormData});
      if (newFormData.mooringDateTime && newFormData.departureDateTime) {
        setBookingDateError(moment(newFormData.mooringDateTime).isSameOrAfter(moment(newFormData.departureDateTime)));
      }
      return newFormData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedDates = {
      ...formData,
      mooringDateTime: formData.mooringDateTime ? moment(formData.mooringDateTime).format() : null,
      departureDateTime: formData.departureDateTime ? moment(formData.departureDateTime).format() : null,
      contactDateTime: formData.contactDateTime ? moment(formData.contactDateTime).format() : null,
    };

    if (!booking || !booking.id) {
      toast.error('Error updating booking, information missing.', {
        position: "top-center",
        hideProgressBar: true
      });
      //console.error('Booking information is missing.');
      return;
    }
    if (!["PortAdmin"].includes(userRole)) {
      toast.error('You do not have the necessary permissions to update this booking.', {
        position: "top-center",
        hideProgressBar: true
      });
      return;
    }
    if (validateForm()) {
      //console.log('Form Data from submit:', formattedDates);
      //console.log("User role: " + userRole);
      
      try{
       const response = await axios.put(Constants.BACK_END + `/api/Booking/${booking.id}`, formattedDates, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log('Booking updated successfully', response.data);
        toast.success('Booking updated successfully', {
          position: "top-center",
          hideProgressBar: true
        });
        setTimeout(() => {
          closeModal();
        }, 1500);
        await fetchBookingListThisYear();
        await clearSelections();
      }catch (error){
        const errorMessage = error.response.data;
        toast.error(`Error updating booking: ${errorMessage}`, {
          position: "top-center",
          hideProgressBar: true
        });
        console.error('Error updating booking:', error);
      }
    }}
  
    const validateForm = () => {
    const newErrors = {};

    if (!formData.vesselName) { newErrors.vesselName = 'Vessel Name is required'; }
    if (!formData.mooringDateTime) { newErrors.mooringDateTime = 'Mooring Date and Time is required'; }
    if (!formData.departureDateTime) { newErrors.departureDateTime = 'Departure Date and Time is required'; }
    if (formData.status == 'CANCELLED') {
    } else if (!formData.berthName) { newErrors.berthName = 'Berth Name is required'; }
    if (!formData.status) { newErrors.status = 'Status is required'; }
    if (!formData.contactDateTime) { newErrors.contactDateTime = 'Contact Date and Time is required'; }
    if (!formData.vesselOrientation) { newErrors.vesselOrientation = 'Orientation is required'; }
    
    if (bookingDateError) {
      console.log('error found in times');
      newErrors.departureDateTime2 = 'Departure Date and Time must be after Mooring Date and Time';
      console.log(newErrors.departureDateTime2);
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const showDeleteModal = () => {
    setShowBookingDeleteModal(true);
  }
  const handleCloseDeleteModal = () => {
    setShowBookingDeleteModal(false);
  }

  const deleteBooking = async () => {
    if (userRole == "Admin" || userRole == "PortAdmin") {
      let apiUrlDeleteBooking = Constants.BACK_END + `/api/Booking/delete/${formData.id}`;
      try{
        await axios.delete(apiUrlDeleteBooking, { headers: { Authorization: `Bearer ${token}` } })
        toast.success('Booking has been deleted!', {
          position: "top-center",
          hideProgressBar: true
        });
        fetchBookingListThisYear();
        setTimeout(() => {
          closeModal();
        }, 1500);
      }catch(error){
        toast.error(`Error deleting this booking: ${error.response?.data || 'An unknown error occurred.'}`, {
          position: "top-center",
          hideProgressBar: true,
          message: error.response.data
        });
        console.error('Error deleting booking:', error.response.data);
      }
    } else {
      toast.error('You do not have permission to delete this booking.', {
        position: "top-center",
        hideProgressBar: true
      });
    }
  }

  return (
    <div className="modal-container">
       <ToastContainer />
      <form ref={modalRef} className="row g-3" id="bookingsUpdateModal" onSubmit={handleSubmit}>
        {bookingDateError && (
          <div className="col-md-12">
            <div className="alert alert-danger modalDisplay" role="alert">
              Mooring date and time must be before departure date and time.
            </div>
          </div>
        )}

        <div className="col-md-6">
          <label className="modalLables" htmlFor="agent">Agent</label>
          <input
            type="text"
            className="form-control modalDisplay"
            id="agent"
            value={formData.agent}
            onChange={handleChange}
            placeholder="Agent"
          />
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="contactDate">Contact Date <span className="required">*</span></label>
          <DateTime
            className="form-control modalDisplay"
            value={formData.contactDateTime ? moment(formData.contactDateTime) : ''}
            selected={moment(formData.contactDateTime).format()}
            onChange={date => handleDateChange(date, "contactDateTime")}
          />
        </div>


        <div className="col-md-6">
          <label className="modalLables" htmlFor="vesselName">Vessel Name <span className="required">*</span></label>
          <select
            className={`form-control modalDisplay ${errors.vesselName ? 'is-invalid' : ''}`}
            id="vesselName"
            value={formData.vesselName}
            onChange={handleChange}
          >
            <option value="">Select Vessel</option>
            {vesselNames.map((vessel, index) => (
              <option key={index} value={vessel.name}>
                {vessel.name}
              </option>
            ))}
          </select>
          {errors.vesselName && <div className="invalid-feedback">{errors.vesselName}</div>}
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="berthName">Berth Name  <span className="required">*</span></label>
          <select
            className={`form-control modalDisplay ${errors.berthName ? 'is-invalid' : ''}`}
            id="berthName"
            value={formData.berthName}
            onChange={handleChange}
            disabled={formData.status === 'CANCELLED'}
          >
            <option value="">Select Berth</option>
            {berthNames.map((name, index) => (
              <option key={index} value={name}>
                {name}
              </option>
            ))}
          </select>
          {errors.berthName && <div className="invalid-feedback">{errors.berthName}</div>}
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="vesselOrientation">Orientation</label>
          <select
            className={`form-control modalDisplay ${errors.vesselOrientation ? 'is-invalid' : ''}`}
            id="vesselOrientation"
            value={formData.vesselOrientation}
            onChange={handleChange}
          >
            <option value="">Select Orientation</option>
            <option value="PORT">PORT</option>
            <option value="STARBOARD">STARBOARD</option>
          </select>
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="status">Status</label>
          <select
            className={`form-control modalDisplay ${errors.status ? 'is-invalid' : ''}`}
            id="status"
            value={formData.status}
            onChange={handleChange}
          >
            <option value="">Select Status</option>
            <option>TENTATIVE</option>
            <option>CONFIRMED</option>
            <option>CANCELLED</option>
          </select>
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="mooringDateTime">Mooring Date and Time <span className="required">*</span></label>
          <DateTime
            className={`form-control modalDisplay ${errors.mooringDateTime ? 'is-invalid' : ''}`}
            value={formData.mooringDateTime ? moment(formData.mooringDateTime) : ''}
            selected={formData.mooringDateTime}
            onChange={date => handleDateChange(date, "mooringDateTime")}
          />
          {errors.mooringDateTime && <div className="invalid-feedback">{errors.mooringDateTime}</div>}
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="departureDateTime">Departure Date and Time <span className="required">*</span></label>
          <DateTime
            className={`form-control modalDisplay ${errors.departureDateTime ? 'is-invalid' : ''}`}
            value={formData.departureDateTime ? moment(formData.departureDateTime) : ''}
            selected={formData.departureDateTime}
            onChange={date => handleDateChange(date, "departureDateTime")}
          />
          {errors.departureDateTime && <div className="invalid-feedback">{errors.departureDateTime}</div>}
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="specialNote">Special Note (500 Characters)</label>
          <textarea
            type="text"
            className="form-control modalDisplay"
            id="specialNote"
            maxLength={500}
            value={formData.specialNote}
            onChange={handleChange}
            placeholder="Special Note"
          />
        </div>

        {formData.status === 'CANCELLED' &&
          <div className="col-md-6">
            <label className="modalLables" htmlFor="cancellationReason">Cancellation Reason</label>
            <input
              type="text"
              className="form-control modalDisplay"
              id="cancellationReason"
              value={formData.cancellationReason}
              onChange={handleChange}
              placeholder="Cancellation Reason"
            />
          </div>}

        {/* <div className="col-md-6">
          <label className="modalLables" htmlFor="portName">Port Id</label>
          <div id="portName">{userPortId}</div>
        </div> */}

        <button type="submit" className="btn btn-primary" >Update Booking</button>
        <button type="button" className="btn btn-danger" onClick={showDeleteModal}>Delete Booking</button>
        <button type="button" className="btn btn-warning" onClick={handleCloseModal}>Cancel</button>

      </form>

      <div className={`modal fade ${showBookingDeleteModal ? 'show' : ''}`} style={{ display: showBookingDeleteModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button type="button" className="modalCancel" onClick={handleCloseDeleteModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this booking?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={deleteBooking}>Delete</button>
              <button type="button" className="btn btn-warning" onClick={() => setShowBookingDeleteModal(false)}>Cancel</button>

            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default BookingsUpdateModal;
