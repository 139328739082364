import React, {useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Constants from '../../Constants';
import '../../App.css'
import {FaRegSquarePlus} from "react-icons/fa6";
import {Columns} from "../Vessels/VesselsTableColumns";
import {useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import DateTime from "react-datetime";
import GlobalFilter from "../Vessels/VesselsTableGlobalFilter";
import {FaAngleLeft, FaAngleRight, FaSort} from "react-icons/fa";
import {ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {checkAuthenticateIssue} from "../Login/LoginUtil";
import useRefreshToken from "../../Contexts/useRefreshToken";


function Vessels() {

    //refresh token upon page load
    useRefreshToken();

    const navigate = useNavigate();
    const [vessels, setVessels] = useState([]);

    const handleDelete = (vessel) => {
        window.alert("about to delete a vessel-->".concat(vessel.id).concat(" ").concat(vessel.name));
        const token = localStorage.getItem("jwtToken");
        console.log('deleting');

        axios.delete(`https://localhost:7141/api/Vessel/${vessel.id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log('deleted - fetching list again');
                fetchList();
            })
            .catch(error => {
                console.error('Error fetching vessels:', error);
                if(checkAuthenticateIssue(error)){
                    navigate("/Login")
                };

            });
    };

    //
    // const handleDoors = (vessel) => {
    //   window.alert("doors for a vessel-->".concat(vessel.name));
    // };

    const handleImages = (vessel) => {
        window.alert("images for a vessel-->".concat(vessel.name));
    };

    const handleSelect = (vessel) => {
    };

    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = () => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");

        axios.get(Constants.BACK_END + '/api/Vessel', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setVessels(response.data);
            })
            .catch(error => {
                console.error('Error fetching vessels:', error);
                if(checkAuthenticateIssue(error)){
                    navigate("/Login")
                };
            });
    };

    const columns = useMemo(() => Columns({handleSelect, handleDelete}), [])
    const data = vessels;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
    } = useTable({
            columns,
            data
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const {globalFilter} = state;
    const {pageIndex} = state;


    return (
        <div>
            <div className='top-container'>
                <div className='top-container-inside'>
                    <h1 className="bookingHeader">Vessels</h1>

                    <Link
                        to={{
                            pathname: `/VesselDetails/add/`,
                        }}
                    >
                        <button className="btn addBooking"><FaRegSquarePlus className='addBookingIcon'/></button>
                    </Link>
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                </div>

                {/*<div className='top-container-inside'>*/}

                {/*    <div className='top-container-interior'>*/}
                {/*    </div>*/}

                {/*    <div className='top-container-interior'>*/}
                {/*    </div>*/}

                {/*    <div className='top-container-interior'>*/}
                {/*        <>*/}
                {/*        </>*/}
                {/*    </div>*/}

                {/*</div>*/}


            </div>

            <div className='table-container'>
                <table className="table" {...getTableProps()}>
                    <thead>
                    {
                        headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => {
                                    const isLastColumn = index === headerGroup.headers.length - 1;
                                    return (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {!isLastColumn && (
                                                <span style={{marginLeft: '5px'}}>
                            <FaSort/>
                          </span>
                                            )}
                                        </th>
                                    );
                                })
                                }
                            </tr>
                        ))
                    }
                    </thead>

                    <tbody {...getTableBodyProps()}>
                    {
                        page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>{
                                    row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })
                                }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>

            </div>

            <div className="pagination">

                <button className="btn previous" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft
                    className='previousIcon'/></button>
                <span>
          {' '} Page{' '}
                    <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
                <button className="btn next" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight
                    className='nextIcon'/></button>
                <ToastContainer/>
            </div>


        </div>


    );


}

export default Vessels;


