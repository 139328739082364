import axios from 'axios';
import Constants from '../../Constants';
import { toast } from 'react-toastify';
import { useBookingResourcesContext } from "../../Contexts/useBookingResourcesContext";

const useOperationsMapBookingResources = (token) =>{

const { bookingResources, setBookingResources } = useBookingResourcesContext();


const addGangwayReservation = async (formData, closeModal)=> {
    try{
        await axios.post(Constants.BACK_END + `/api/BookingResource`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }})
            toast.success('Reservation added successfully', {
                position: "top-center",
                hideProgressBar: true
            });
            setTimeout(() => {
                closeModal();
            }, 1500);        
        } catch (error){
            const errorMessage = error.response.data;
            toast.error(`Error adding reservation: ${errorMessage}`, {
                position: "top-center",
                hideProgressBar: true,
            });
            console.error('Error adding reservation:', error);
            throw error;
    } 
}

const updateGangwayReservation = async (formData, closeModal) => {
    try {
      const response = await axios.put(`${Constants.BACK_END}/api/BookingResource/gangwayUpdate/${formData.id}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      toast.success('Gangway Reservation updated successfully', {
        position: "top-center",
        hideProgressBar: true
      });
      setTimeout(() => {
        closeModal();
      }, 1500);
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      toast.error(`Error updating gangway reservation: ${errorMessage}`, {
        position: "top-center",
        hideProgressBar: true
      });
      console.error('Error updating gangway reservation!:', error);
      console.log('Error response:', errorMessage);
    }
  };
  
    const handleSelectBookingResource = async (BookingId, VesselDoorId, GangwayId, berthId) => {
        const selectedResource = {
            bookingId: BookingId,
            vesselDoorId: VesselDoorId,
            gangwayId: GangwayId
        }
        try {
            await axios.put(Constants.BACK_END + `/api/Booking/updateBerth/${BookingId}/${berthId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            await axios.post(`${Constants.BACK_END}/api/BookingResource`, selectedResource, { headers: { Authorization: `Bearer ${token}` } })
            toast.success('Settings saved', {
                position: "top-center",
                hideProgressBar: false
            });
            
            setBookingResources(prevResources => [...prevResources, selectedResource]);

        } catch (error) {
            const errorMessage = error.response.data;
            toast.error(`Error updating: ${errorMessage}`, {
                position: "top-center",
                hideProgressBar: true
            });
            console.error('Error updating:', error);
        }
    }

    const handleRemoveBookingResource = async (BookingId, VesselDoorId, GangwayId) => {
        console.log("Hello trying to delete gangway reservation. ", BookingId, VesselDoorId, GangwayId)
        try {
            await axios.delete(`${Constants.BACK_END}/api/BookingResource/delete/bookingId/${BookingId}/vesselDoorId/${VesselDoorId}/gangwayId/${GangwayId}`,
                { headers: { Authorization: `Bearer ${token}` } });
            toast.success('Resource removed', {
                position: "top-center",
                hideProgressBar: false
            });
            setBookingResources(prevResources => prevResources.filter(resource => !(resource.bookingId === BookingId
                && resource.vesselDoorId === VesselDoorId
                && resource.gangwayId === GangwayId)))

        } catch (error) {
            console.error('Error deleting resource:', error);
        }
    }

    // const handleUpdateBookingResource = async (BookingId, VesselDoorId, GangwayId) => {
    //     try {
    //         await axios.put(`${Constants.BACK_END}/api/BookingResource/update/bookingId/${BookingId}/vesselDoorId/${VesselDoorId}/gangwayId/${GangwayId}`,
    //             { headers: { Authorization: `Bearer ${token}` } })
    //         toast.success('Resource updated', {
    //             position: "top-center",
    //             hideProgressBar: false
    //         });
    //     } catch (error) {
    //         console.error('Error updating resource:', error);
    //     }
    // }

    const fetchBookingResources = async (bookingId) => {
        try{
            const response = await axios.get(`${Constants.BACK_END}/api/BookingResource/bookingId/${bookingId}`, { headers: { Authorization: `Bearer ${token}` } })
            setBookingResources(prevResources => {
                const newResourcesMap = new Map(response.data.map(resource => [resource.id, resource]));
            
                const updatedResources = prevResources.map(resource => 
                    newResourcesMap.has(resource.id) ? newResourcesMap.get(resource.id) : resource
                );

                response.data.forEach(newResource => {
                    if (!prevResources.some(resource => resource.id === newResource.id)) {
                        updatedResources.push(newResource);
                    }
                });
                return updatedResources;  
            });
            //console.log("getting booking resources: ", JSON.stringify(response.data, null, 2));

        } catch (error){
            console.error('Error fetching booking resources:', error);
        }
    };

    const hasBookingResource = (bookingId) => {
        return bookingResources.some(resource => resource.bookingId === bookingId);
    }

    return {
        bookingResources,
        handleRemoveBookingResource,
        handleSelectBookingResource,
        //handleUpdateBookingResource,
        fetchBookingResources,
        hasBookingResource,
        addGangwayReservation,
        updateGangwayReservation
    };
}

export default useOperationsMapBookingResources;