import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from "recharts";
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import UserRoleContext from '../../Contexts/UserRoleContext';
import Constants from '../../Constants';
import moment from 'moment';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';



function OperationsDateTide({ getDateChange, token, userPortId }) {
    const [tide, setTide] = useState([]);
    const [gangwayReservation, setGangwayReservation] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());


    const TideCustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const formattedDate = new Date(label).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
            const height = payload[0].value.toFixed(2);
            return (
                <div className="customTooltip">
                    {`Time: ${formattedDate}    `}
                    {`Height: ${height} m`}
                </div>
            );
        }
    }

    const handleDateChange = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        setSelectedDate(date)
        axios.get(`${Constants.BACK_END}/api/Tide/${formattedDate}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setTide(response.data);
            })
            .catch(error => {
                console.error('Error fetching tide information:', error);
            });

            axios.get(`${Constants.BACK_END}/api/GangwayReservation/date/${formattedDate}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setGangwayReservation(response.data);
            })
            .catch(error => {
                console.error('Error fetching gangway reservation information:', error);
            });

        getDateChange(formattedDate, tide)
    }



    return (
        <div className='operationsTopContainer'>

<div className='operationsTopContainerInterior col-md-4'>
                <label className="bookingLabels" htmlFor="selectedDate">Gangway Availability</label>
                <ResponsiveContainer width="50%" height={100}>
                <BarChart data={gangwayReservation}>

                    </BarChart>
                </ResponsiveContainer>
            </div>


            <div className='operationsTopContainerInterior col-md-4'>
                <button className="btn today" onClick={() => handleDateChange(new Date())}>Today</button>
                <label className="bookingLabels" htmlFor="selectedDate">Select Date:</label>
                <DateTime
                    className='selectedDate'
                    value={selectedDate}
                    onChange={handleDateChange}
                    inputProps={{ placeholder: 'Select Date', readOnly: true }}
                    timeFormat={false}
                    closeOnSelect={true}
                />
            </div>
            <div className='operationsTopContainerInterior col-md-4'>
                <label className="bookingLabels" htmlFor="selectedDate">Tide Data</label>
                <ResponsiveContainer width="50%" height={100}>
                    <LineChart data={tide}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="tideDateTime" tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} />
                        <YAxis dataKey="height" > </YAxis>
                        <Tooltip cursor={false} content={<TideCustomTooltip />} />
                        <Line type="monotone" dataKey="height" stroke="#22418a" dot={false} activeDot={{ r: 5 }} />
                    </LineChart>
                </ResponsiveContainer>
            </div>

        </div>
    )
}
export default OperationsDateTide